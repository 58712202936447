import { Language } from "../Language";

let isMute: boolean = false;
let language: string = Language.FR;

//get sound from local storage
if(localStorage.getItem("isMute") === "true"){
  isMute = true;
}else{
  localStorage.setItem("isMute", "false")
}

//get langauge from local storage
if(localStorage.getItem("language") === Language.CR){
  language = Language.CR;
}else{
  localStorage.setItem("language", Language.FR)
}

export type InitialStateType = {
  token: string,
  isLoading: boolean,
  user : User | null,
  error: string,
  isAuthChecked: boolean,
  redirectPathOnAuth: string,
  isMute: boolean,
  language: string
}

const initialState: InitialStateType = {
  token: '',
  isLoading: false,
  user: null,
  error: '',
  isAuthChecked: false,
  isMute: isMute,
  redirectPathOnAuth: '',
  language : language
}

export default initialState;