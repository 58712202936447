import React,{ useState, useEffect } from 'react'

interface CounterProps {
  percentage: number
}

export const Count: React.FC<CounterProps> = ({percentage}) => {
  const [count, setCount] = useState<number>(0)
  useEffect(() => {
    let start = 0;
    const end = percentage
    if(end === start) return

    let timer = setInterval(()=>{
      start++;
      setCount(start)
      if (start === end) clearInterval(timer)   
    },2500/end)
    
  }, [percentage])

  return (
    <div className="text-white">
      {count}%
    </div>
  );
}