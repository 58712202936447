import React from 'react'

interface PrizeListProps {
  currentPrize : number,
  prizes: Array<Prize>
  isOver: boolean
}

export const PrizeList: React.FC<PrizeListProps> = ({currentPrize, prizes, isOver}) => {

  return (
    <div>
      <ul className={`flex flex-col-reverse bg-gray-900 gap-y-1 mt-12 border border-yellow-500 rounded ${isOver && "-right-60"} right-0 transition-all duration-1000 relative`} >
        {prizes.map((prize,i)=>{
          let color ="text-white"
          if(prize.money === 1000 || prize.money === 32000) color = "text-green-500"
          if(i === currentPrize) color = "text-gary-900"
        return <li key={prize.money} className={`${color} px-5 md:px-10 text-center z-10`}>{prize.money} 😊</li>
        })}
      </ul>
      <div className={`text-white px-24 md:px-28 text-center bg-yellow-500 p-3 border border-yellow-500 rounded relative ${isOver && "-right-60"} right-0 transition-all duration-1000`} style={{bottom: currentPrize ? `${(currentPrize+1) * 27.8}px`: '25px'}}></div>
    </div>
  );
}