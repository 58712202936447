import React,{ useRef, useEffect, useState, useContext } from 'react'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { resetPassword } from '../api';
import { AuthContext } from '../context/context'
import {ReactComponent as CloseIcon} from '../icons/close.svg'

type TParams = { token: string };

interface ResetPasswordFormDataTypes {
  password: string,
  confirmPassword: string
}

export const ResetPassword: React.FC<RouteComponentProps<TParams>> = ({match}) => {
  const {state} = useContext(AuthContext)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {register, handleSubmit, errors, watch} = useForm<ResetPasswordFormDataTypes>();

  const password = useRef({});
  password.current = watch("password");

  const history = useHistory()
  useEffect(() => {
    if(state.token) history.push('/')
   }, [history, state.token])

  const onSubmit = handleSubmit(async ({password, confirmPassword})=>{
    setIsLoading(true)
    const {success, error} = await resetPassword(password, confirmPassword,match.params.token)
    if(success) setSuccess(success)
    if(error) setError(error)
    setIsLoading(false)
  })

  return (
    <div className="flex-grow flex flex-col justify-center items-center bg-gray-700">
      <h2 className="font-bold lg:text-4xl md:text-2xl sm:text-2xl text-yellow-500">Mot de passe oublié</h2>
      {error && !success && 
        <div className="max-w-sm w-full mx-auto bg-red-300 border border-red-400 text-gary-700 px-4 py-3 mt-3 rounded relative" role="alert">
          <p className="text-sm text-gray-900">{error}</p>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <CloseIcon  onClick={()=> setError('')} className="fill-current h-6 w-6 text-red-500" role="close button"/>
          </span>
        </div>
      }
      { success ? 
          <div className="bg-green-400 border-t-4 border-green-200 rounded-b text-teal-darkest max-w-md w-full mx-auto mt-4 p-8" role="alert">  
            <p className="font-bold">{success}</p>
            <p className="underline text-gray-900"><Link to="/login">se connecter</Link></p>
          </div>   
          :
          <div className="max-w-md w-full mx-auto mt-4 p-8 text-white">
          <form className="space-y-6" onSubmit={onSubmit}>
          <div>
              <label htmlFor="password" className="text-sm block font-semibold">Nouveau Mot de passe</label>
              <input 
                ref={register({
                  required: true,
                  minLength: 8,
                  maxLength: 24
                })}
                style={{borderColor: errors.password ? "red" : ""}}
                type="password" id="password" name="password" className="w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
              {errors.password && <div className="text-red-500">Votre Mot de pass doit être entre 8 et 24 caractères</div>}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="text-sm block font-semibold">Confirmer le nouveau mot de passe</label>
              <input
                ref={
                  register({
                    validate: value => value === password.current || "Les mots de passe ne correspondent pas"
                  })
                }  
                type="password" id="confirmPassword" name="confirmPassword" className="w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
              {errors.confirmPassword && <div className="text-red-500">{errors.confirmPassword.message}</div>}
            </div>
            <div>
            <button className={`flex justify-center space-x-2 w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 rounded text-gray-900 ${isLoading && "cursor-not-allowed"}`} disabled={isLoading ? true : false }>
                <span>Envoyer un mail de réinitialisation</span>
                {isLoading && <span style={{borderTopColor:"transparent"}} className="block border-solid animate-spin rounded-full border-gray-900 border-4 h-6 w-6"></span>}
              </button>
            </div>
          </form>
          <Link className="text-sm underline" to="/login">Revenir à la page de connexion</Link>
        </div>
      }
    </div>
  );
}
