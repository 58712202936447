import React,{ useState, useEffect, useContext, useRef } from 'react';
import { PrizeList } from '../components/PrizeList';
import { Question } from '../components/Question'
import { Answer } from '../components/Answer'
import data from '../data'
import { Audiance } from '../components/jokers/Audiance';
import '../styles/Audiance.css'
import { FiftyFifty } from '../components/jokers/FiftyFifty';
import { PhoneCall } from '../components/jokers/PhoneCall';
import { ChangeQuestion } from '../components/jokers/ChangeQuestion';
import { VoteModal } from '../components/jokers/VoteModal';
import { arrayOfRandomNumbers, changeAudio, getIndexOfCorrectAnswer, shuffle, sortQuestions } from '../helpers/helpers';
import { Link, useParams } from 'react-router-dom';
import { addResult, getRandomQuestions } from '../api';
import {ReactComponent as RightArrow} from '../icons/right-arrow.svg'
import {ReactComponent as CloseIcon} from '../icons/close.svg'
import { AuthContext } from '../context/context';
import { useSwipeable } from "react-swipeable";
import { CSSTransition } from "react-transition-group"

//TODO change question section to grid

interface GameProps {
  updateResults: (result: Summary) => void
}

export enum jokersEnum {
  FIFTY_FIFTY = "FIFTY_FIFTY",
  AUDIENCE = "AUDIENCE",
  PHONE_CALL = "PHONE_CALL",
  CHANGE_QUESTION = "CHANGE_QUESTION",
}

const JokersInitialState = new Map<string,boolean>([
  [jokersEnum.FIFTY_FIFTY,false],
  [jokersEnum.AUDIENCE,false],
  [jokersEnum.PHONE_CALL,false],
  [jokersEnum.CHANGE_QUESTION,false],
])

export const Game: React.FC<GameProps> = (props) => {
  const {state} = useContext(AuthContext)
  const [prizesModal, setPrizesModal] = useState<boolean>(false)
  const [jokersModal, setJokersModal] = useState<boolean>(false)
  //index of question
  const [index, setindex] = useState<number>(0)
  const [text,setText] = useState<string>('')
  const [error,setError] = useState<string>('')
  const [currentPrizeIndex, setCurrentPrizeIndex] = useState<number>(0)
  const [guaranteedPrize, setGuaranteedPrize] = useState<number>(0)
  const [questions, setQuestions] = useState<Array<Question>>([])
  const [currentQuestion, setCurrentQuestion] = useState<Question>()
   //enable/disable jokers permanently
  const [jokers, setJokers] = useState<Map<string,boolean>>(JokersInitialState)
  //array of audience's vote, order is important
  const [audianceVotes, setAudainceVotes] = useState<Array<number>>([0,0,0,0])
  const [arrayOfAnswersDisabilty, setArrayOfAnswersDisabilty] = useState<boolean[]>([false,false,false,false])
  const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(false)
  const [isPhoneCallPopupDisplayed, setIsPhoneCallPopupDisplayed] = useState<boolean>(false)
  //enable/disable jokers temporarily every turn (once the user has answered)
  const [canUseJokers, setCanUseJokers] = useState<boolean[]>([false,false,false,false])
  const [isOver, setIsOver] = useState<boolean>(false)

  const node = useRef<HTMLDivElement>(null)

  const prizesList: Array<Prize> = data.prizesList
  const {type} = useParams<{type:string}>();



  //fetch data on mount
  useEffect(() => {
    getRandomQuestions(type).then((res)=>{
      if(res.questions.length > 0){
        const sortedQuestions = sortQuestions(res.questions)
        //shuffle array of answers to change the order
        sortedQuestions.forEach(question =>{
          question.answers = shuffle(question.answers);
        })
        setCurrentQuestion(sortedQuestions[index])
        setQuestions(sortedQuestions)
      }else{
        setError("Ooops!! Aucune donnée est trouvée")
      }
    }).catch(error=> setError(error))
  }, [type])

  //reset default values on render
  useEffect(() => {
    setArrayOfAnswersDisabilty([false,false,false,false])
    if(index !== 0){
      setCurrentQuestion(questions[index])
      //localStorage.setItem("currentQuestion", JSON.stringify(questions[index]))
      //localStorage.setItem("index", JSON.stringify(index))
    } 
    
  }, [index, questions])

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      setJokers(JokersInitialState)
    };
  }, []);

  const handleIndex = ()=>{
    setindex(index=>index+1)
  }
  const disableJokersTemporarily = ()=>{
    setCanUseJokers([true,true,true,true])
  }

  const finishGame = (prize: number)=>{
    setIsOver(isOver => !isOver)
    let numberOfAnsweredQuestions = index
    if(prize === 1000000) numberOfAnsweredQuestions = index + 1
    addResult(numberOfAnsweredQuestions, prize)
    setJokers(JokersInitialState)
    //localStorage.setItem("jokers", "null")
    //localStorage.setItem("index", "null")
    //localStorage.setItem("questions", "null")
    //localStorage.setItem("type", "null")
  }

  const handleAnswer =(answer: Answer)=>{
    setTimeout(()=>{
      if(answer.isCorrect && prizesList[currentPrizeIndex].money === 1000000){
        setText(`Félicitations, Vous avez gagné 1000000 😊`)
        setGuaranteedPrize(1000000)
        finishGame(1000000)
        return
      }else if(answer.isCorrect){
        handleIndex()
        setCurrentPrizeIndex(currentPrizeIndex => currentPrizeIndex + 1)
      }else if(!answer.isCorrect && prizesList[currentPrizeIndex].money > 32000){
        setText(`Félicitations, Vous avez gagné 32000 😊`)
        setGuaranteedPrize(32000)
        finishGame(32000)
        return
      }else if(!answer.isCorrect && prizesList[currentPrizeIndex].money > 1000){
        setText(`Félicitations, Vous avez gagné 1000 😊`)
        setGuaranteedPrize(1000)
        finishGame(1000)
        return
      }
      else{
        setText(`Game over`)
        finishGame(0)
      }
      setCanUseJokers([false,false,false,false])
    },2000)
    if(currentQuestion) props.updateResults({question : currentQuestion,choice: answer.text})
  }

  const handleClick = (e: any)=>{
    if(node.current?.contains(e.target)){
      return
    }
    setIsPhoneCallPopupDisplayed(false)
    setIsModalDisplayed(false)
  }

  const handlePhoneCall = ()=>{
    if(!state.isMute) changeAudio('../sounds/lifelines.mp3')
    const indexOfCorrectAnswer = getIndexOfCorrectAnswer(currentQuestion?.answers)
    const letters = ["A","B","C","D"]
    const newJokers = new Map(jokers)
    setJokers(newJokers.set(jokersEnum.PHONE_CALL, true))
    //localStorage.setItem("jokers",JSON.stringify(Array.from((jokers))))
    setText(`Je pense que la bonne réponse est la réponse : ${letters[indexOfCorrectAnswer]}`)

    setIsPhoneCallPopupDisplayed(isPhoneCallPopupDisplayed => !isPhoneCallPopupDisplayed)
  }

  const handleChangeQuestion= ()=>{
    if(!state.isMute) changeAudio('../sounds/lifelines.mp3')
    if(index < questions.length){
      setindex(index => index + 1)
    }
    const newJokers = new Map(jokers)
    setJokers(newJokers.set(jokersEnum.CHANGE_QUESTION, true))
    //localStorage.setItem("jokers",JSON.stringify(Array.from((jokers))))
  }

  /** Function that deletes two wrong asnwers and set the state to the new modified question */
  const handleFiftyFifty = ()=>{
    if(!currentQuestion) return;
    if(!state.isMute) changeAudio('../sounds/lifelines.mp3')
    let counter: number = 0;
    let indexesOfTwoWrongAnswers: boolean[] = [false,false,false,false];
    /**Get the index of the two worng answers to disable them */
    for (const answer of currentQuestion.answers) {
      if(counter<2){
        if(!answer.isCorrect){
          indexesOfTwoWrongAnswers[currentQuestion.answers.indexOf(answer)] = true;
          counter++
        }
      }
    }
    const newJokers = new Map(jokers)
    setJokers(newJokers.set(jokersEnum.FIFTY_FIFTY, true))
    //localStorage.setItem("jokers",JSON.stringify(Array.from((jokers))))
    setArrayOfAnswersDisabilty(indexesOfTwoWrongAnswers)
  }

  const handleAudiance = ()=>{
    if(!state.isMute) changeAudio('../sounds/lifelines.mp3')
    const randomNumbers = arrayOfRandomNumbers(arrayOfAnswersDisabilty)
    const indexOfCorrectAnswer = getIndexOfCorrectAnswer(currentQuestion?.answers)
    const maxNumber = Math.max(...randomNumbers)
    const indexOfMaxNumber = randomNumbers.indexOf(maxNumber)
    const temp = randomNumbers[indexOfMaxNumber]
    randomNumbers[indexOfMaxNumber] = randomNumbers[indexOfCorrectAnswer]
    randomNumbers[indexOfCorrectAnswer] = temp
    setAudainceVotes(randomNumbers)
    
    setIsModalDisplayed(isModalDisplayed => !isModalDisplayed)
    const newJokers = new Map(jokers)
    setJokers(newJokers.set(jokersEnum.AUDIENCE, true))
    //localStorage.setItem("jokers",JSON.stringify(Array.from((jokers))))
  }
  //Handle modals on mobile with swipe 
  const handleSwipeLeft = useSwipeable({
    onSwipedLeft: ()=> setJokersModal(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })
  const handleSwipeRight = useSwipeable({
    onSwipedRight: () => setPrizesModal(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })
  const handleDisplayPrizeModal = ()=>{
    setPrizesModal(prizesList => !prizesList)
  }
  const handleDisplayJokersModal = ()=>{
    setJokersModal(jokersModal => !jokersModal)
  }

  const resign = ()=>{
    if(index > 0){
      //setGuaranteedPrize(prizesList[currentPrizeIndex-1].money)
      setText(`Game over, Vous avez gagné ${prizesList[currentPrizeIndex-1].money} 😊`)
      finishGame(prizesList[currentPrizeIndex-1].money)
    }
  }

  let confettis:any = [];
  for(let i=0;i<177;i++){
    confettis.push(i)
  }

  return (
    <>
    {error ? <p className="text-center flex-grow">{error}</p> 
    :
    <main className="flex-grow flex flex-col items-center bg-gray-900 bg-opacity-40 relative overflow-hidden" style={{backgroundSize:"cover", backgroundImage:  "url(/bg.jpg)" , backgroundPosition:"center", backgroundRepeat:"no-repeat"}}>
      <div className="flex-grow w-screen h-max bg-gray-900 bg-opacity-40 flex flex-col items-center overflow-hidden">
        <button onClick={resign} className={`absolute ${isOver && "hidden"} transition-all duration-500 text-xs md:text-lg text-gray-900 bg-yellow-500 hover:bg-gray-900 hover:text-yellow-500 border right-3 top-2 xl:right-20 xl:top-10 rounded border-yellow-500 p-1`}>Abandonner</button>
        <div className={`hidden xl:block absolute lg:right-4 overflow-hidden`} style={{top:"15%"}}>
          <PrizeList isOver={isOver}  currentPrize={currentPrizeIndex} prizes={prizesList}/>
        </div>

        <p className={`${isOver && "opacity-100"} text-2xl lg:text-3xl xl:text-5xl transition opacity-0 text-yellow-500 duration-1000 text-center pt-8`}>{text}</p>
        <p className={`${isOver && "hidden"} text-xl text-yellow-500 relative top-2 xl:hidden`}>Gain : {prizesList[currentPrizeIndex].money} 😊</p>
        {isOver &&
        <div className={`${isOver && "opacity-100"} flex transition opacity-0 duration-1000 space-x-2 absolute top-1/4`}>
          <Link to='/summary' className={`${isOver && "opacity-100"} text-xl transition opacity-0 text-yellow-500 duration-500 border rounded p-2 border-yellow-600 hover:bg-gray-900`}>Résumé</Link>
          <Link to='/' className={`${isOver && "opacity-100"} text-xl transition opacity-0 text-yellow-500 duration-500 border rounded p-2 border-yellow-600 hover:bg-gray-900`}>Rejouer</Link>
        </div>
        }


        <VoteModal heights={audianceVotes} modalDisplay={isModalDisplayed}/>

        <div ref={node} className="absolute bottom-1/3 md:bottom-1/2 lg:relative lg:top-0 max-w-screen z-30"> 
          {isPhoneCallPopupDisplayed && 
            <div className="border border-yellow-500 rounded-md bg-gray-900 sm:w-max">
              <p className="line anim-typewriter text-sm md:text-lg">{text}</p>
            </div>
          }
        </div>

        <div className='overflow-y-hidden absolute bottom-8 md:bottom-16 max-w-4xl mx-14 xl:-ml-8'>
          <div className={`flex flex-col ${isOver && "-bottom-96"} bottom-0 transition-all duration-1000 relative`}>      
            <Question questionText={currentQuestion?.text}/>
            <Answer answers={currentQuestion?.answers} answersDisabilty={arrayOfAnswersDisabilty} handleAnswer={handleAnswer} disableJokersTemporarily={disableJokersTemporarily}/>
          </div>
        </div>
        
        <CSSTransition
          in={jokersModal}
          classNames="jokersModal"
          timeout={1000}
          unmountOnExit
        >
          <div {...handleSwipeLeft} className={`h-screen w-screen bg-gray-700 bg-opacity-80 absolute z-20`} >
            <CloseIcon onClick={()=>setJokersModal(false)} className="w-8 h-8 md:w-12 md:h-12 fill-current text-yellow-500 absolute right-12 top-12" style={{top:"5%",right:"10%"}}/>
            <div className={`flex space-y-4 absolute left-2 flex-col z-30`} style={{top:"10%"}}>
              <FiftyFifty jokers={jokers} handleFiftyFifty={handleFiftyFifty} isOver={isOver} canUseJokers={canUseJokers[0]}/>
              <Audiance jokers={jokers} handleAudiance={handleAudiance} isOver={isOver} canUseJokers={canUseJokers[1]}/>
              <PhoneCall jokers={jokers} handlePhoneCall={handlePhoneCall} isOver={isOver} canUseJokers={canUseJokers[2]}/>  
              <ChangeQuestion jokers={jokers} handleChangeQuestion={handleChangeQuestion} isOver={isOver} canUseJokers={canUseJokers[3]}/>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={prizesModal}
          classNames="prizesModal"
          timeout={1000}
          unmountOnExit
        >
          <div {...handleSwipeRight} className={`h-full w-full bg-gray-700 bg-opacity-80 absolute z-20`}>
            <CloseIcon onClick={()=>{setPrizesModal(false)}} className="w-8 h-8 md:w-12 md:h-12 fill-current text-yellow-500 absolute right-12 top-12" style={{top:"5%",right:"10%"}}/>
            <div className={`h-full flex justify-center items-start`}>
              <PrizeList isOver={isOver}  currentPrize={currentPrizeIndex} prizes={prizesList}/>
            </div>
          </div>
        </CSSTransition>

        <div className={` hidden xl:flex space-y-4 absolute left-2 flex-col z-30`} style={{top:"20%"}}>
          <FiftyFifty jokers={jokers} handleFiftyFifty={handleFiftyFifty} isOver={isOver} canUseJokers={canUseJokers[0]}/>
          <Audiance jokers={jokers} handleAudiance={handleAudiance} isOver={isOver} canUseJokers={canUseJokers[1]}/>
          <PhoneCall jokers={jokers} handlePhoneCall={handlePhoneCall} isOver={isOver} canUseJokers={canUseJokers[2]}/>  
          <ChangeQuestion jokers={jokers} handleChangeQuestion={handleChangeQuestion} isOver={isOver} canUseJokers={canUseJokers[3]}/>
        </div>

        {/* mobile jokers button */}
        <div className="flex flex-col items-center xl:hidden absolute left-1 top-1/3 overflow-hidden">
          <button 
            onClick={handleDisplayJokersModal}
            className= {` ${isOver && "-left-60"} relative left-0 transition-all duration-1000 uppercase p-2 md:p-3 lg:p-4 shadow-sm hover:shadow-lg`}>
            <RightArrow className="xl:hidden w-6 h-6 md:w-12 md:h-12 fill-current text-yellow-500"/>
          </button> 
          <span className={`${isOver && "-left-60"} left-0 text-xs md:text-base transition-all duration-1000 relative text-yellow-500`}>jokers</span>
        </div>

        {/* mobile prizes button */}
        <div className={`flex flex-col items-center xl:hidden absolute right-1 top-1/3 overflow-hidden`}>
          <button
            onClick={handleDisplayPrizeModal}     
            className= {` ${isOver && "-right-60"} relative right-0 transition-all duration-1000 uppercase p-2  rounded-full md:p-3 lg:p-4 shadow-sm hover:shadow-lg`}>
            <RightArrow className="xl:hidden w-6 h-6 md:w-12 md:h-12 fill-current text-yellow-500 transform rotate-180"/>
          </button> 
          <span className={`${isOver && "-right-60"} right-0 text-xs md:text-base transition-all duration-1000 relative text-yellow-500`}>prix</span>
        </div>

      </div>
      
      {isOver && guaranteedPrize === 1000000 && 
   
        <div className="wrapper">
          {confettis.map((e:any,i: number)=>{
            return <div key={i} className={`confetti-${i}`}></div>})}
        </div>

      }
    </main>
    }
    </>
  );
}
