import React, { createContext, useReducer, Dispatch } from 'react'
import { AuthActions, reducer } from './reducer'
import initialState, { InitialStateType } from './initialState';

//credit to :https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm
interface ContextProps {
  state: InitialStateType;
  dispatch: Dispatch<AuthActions>;
}

const AuthContext = createContext<ContextProps>({
  dispatch: ()=>null , // Dispatch initial value
  state: initialState
})

const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider};