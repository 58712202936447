import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export const Home: React.FC = () => {
  const {t} = useTranslation();

  return (
    <main className="bg-gray-700 text-white flex-grow flex flex-col justify-center items-center px-4">
      <div className="flex flex-col justify-center items-center lg:max-w-4xl">
        <h1 className="my-4 font-bold text-3xl lg:text-4xl pb-10">{t("A propos du jeu")}</h1>
        <p className="lg:text-xl lg:p-2">
        <span className="font-bold text-yellow-500">C’est votre dernier mot ? </span>Mobilisez toutes vos connaissances sur l’autisme et décrochez le jackpot en évitant les questions pièges : retrouvez l’ambiance pleine de suspense de l’émission « Qui veut gagner des millions » Vous avez la possibilité de vous mettre en condition d'emploi et ou d'employeur de personnes autistes
        </p>
      </div>
      <div className="md:my-4 flex flex-col md:flex-row md:justify-center space-y-2 md:space-y-0 md:space-x-2 pt-8">
        <Link className="bg-yellow-500 text-center rounded text-gray-900 font-semibold hover:bg-yellow-400 p-2" to={`game/autistic`}>Vous êtes une personne autiste</Link>
        <Link className="bg-yellow-500 text-center rounded text-gray-900 font-semibold  hover:bg-yellow-400 p-2 lg:py-2 lg:px-5" to={`game/employer`}>Vous êtes un employeur</Link>
      </div>
    </main>
  );
}