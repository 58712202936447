import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFR from "./i18n/french.json"
import translationCR from "./i18n/creole.json"
import {Language} from "./Language"

let defaultLanguage;

localStorage.getItem("language") === Language.FR ? defaultLanguage = Language.FR : defaultLanguage = Language.CR;

const resources = {
  cr: {
    translation: translationCR
  },
  fr: {
    translation: translationFR
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    debug : false,
    fallbackLng: Language.FR,
    //keySeparator: ".",  // to support nested translations
 
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
 
  export default i18n;
