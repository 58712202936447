import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { ReactComponent as CloseIcon } from '../icons/close.svg'
import { contact } from '../api';
import { ReactComponent as PositionIcon } from '../icons/position.svg'

export interface QuestionFormDataTypes {
  name: string,
  email: string,
  message: string
}
export const Contact: React.FC = () => {
  const { register, handleSubmit, errors, reset } = useForm<QuestionFormDataTypes>();
  const [responseMessage, setResponseMessage] = useState<string>('')
  //used to change the color of the pop-up message
  const [responseSuccess, setResponseSuccess] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = handleSubmit(({ email, message, name }) => {

    setIsLoading(true)
    contact(email, name, message).then((res) => {
      if (res.success) {
        setResponseMessage(res.success)
        setResponseSuccess(true)
      }
    }).catch(error => {
      setResponseMessage(error)
      setResponseSuccess(false)
    }).finally(() => {
      setIsLoading(false)
    })
    reset()
  })
  return (
    <main className="flex-1 flex flex-col bg-gray-700 text-white p-4">
      <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-6xl flex items-center justify-center text-yellow-500 md:mt-24">Contact</h1>
      <section className=" flex flex-col lg:flex-row w-full space-x-8 items-center justify-center">
        <form className="pt-10 space-y-4 max-w-xl w-full" onSubmit={onSubmit}>

          {responseMessage &&
            <div className={`mx-auto ${responseSuccess ? "bg-green-300" : "bg-red-400"} text-gray-900 px-4 py-3 mt-3 rounded relative`} role="alert">
              <p className="block sm:inline">{responseMessage}</p>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3"><CloseIcon onClick={() => { setResponseMessage('') }} className="fill-current h-6 w-6 text-red-500" role="button"/></span>
            </div>
          }

          <div>
            <label className="text-sm block font-semibold">Nom<span className="text-red-500">*</span></label>
            <input ref={register({
              required: true,
            })}
              style={{ borderColor: errors.name ? "red" : "" }}
              name="name" className="max-h-28 w-full p-2 border border-gray-300 rounded mt-1 text-gray-900" />
            {errors.name && <div >Votre Nom doit être entre 2 et 50 caractères</div>}
          </div>
          <div>
            <label className="text-sm block font-semibold">Email<span className="text-red-500">*</span></label>
            <input
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalide email',
                }
              })}
              style={{ borderColor: errors.email ? "red" : "" }}
              type="text" name="email" className="max-h-28 w-full p-2 border border-gray-300 rounded mt-1 text-gray-900" />
            <div >{errors.email && errors.email.message}</div>
          </div>
          <div >
            <label className="text-sm block font-semibold">Message<span className="text-red-500">*</span></label>
            <textarea
              ref={register({
                required: true,
              })}
              style={{ borderColor: errors.message ? "red" : "" }}
              name="message" className="max-h-28 w-full p-2 border border-gray-300 rounded mt-1 text-gray-900" />
          </div>
          <span className="text-sm text-white">champ obligatoire*</span>
          <div>
            <button className={`flex justify-center space-x-2 w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 rounded text-gray-900 ${isLoading && "cursor-not-allowed"}`} disabled={isLoading ? true : false}>
              <span>Envoyer</span>
              {isLoading && <span style={{ borderTopColor: "transparent" }}></span>}
            </button>
          </div>
        </form>
        <div className="flex flex-col items-center justify-between content-between">
          <div className="flex  justify-around">
            <div className="p-4">
              <p className="text-yellow-500">Notre adresse</p>
              <p className="text-sm md:text-base">21 rue Edouard Vaillant</p>
              <p className="text-sm md:text-base">37000 TOURS</p>
            </div>
            <div className="p-4">
              <p className="text-yellow-500">Nous joindre</p>
              <a className="text-sm md:text-base hover:underline block" href="tel:+33247054290">Tél : 02 47 05 42 90</a>
              <a className="text-sm md:text-base hover:underline block" href="mailto:contact@o3experts.fr">contact@o3experts.fr</a>
            </div>
          </div>
          <a className="md:mt-8 pb-2" target="blank" href="https://www.google.fr/maps/place/21+Rue+%C3%89douard+Vaillant,+37000+Tours/@47.3880005,0.6944172,17z/data=!3m1!4b1!4m5!3m4!1s0x47fcd436ebe2940d:0xc22e265ea23e242c!8m2!3d47.3878619!4d0.6967109"><PositionIcon className="h-20 w-20 lg:w-40 lg:h-40 fill-current text-yellow-500" /></a>
        </div>
      </section>
      {/* <section className="flex flex-col xl:flex-row items-center justify-evenly w-full">
        <form className="flex-grow space-y-6 max-w-2xl w-full p-8" onSubmit={onSubmit}>
          {responseMessage &&
            <div className={`mx-auto ${responseSuccess ? "bg-green-300" : "bg-red-400"} text-gray-900 px-4 py-3 mt-3 rounded relative`} role="alert">
              <p className="block sm:inline">{responseMessage}</p>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3"><CloseIcon onClick={() => { setResponseMessage('') }} className="fill-current h-6 w-6 text-red-500" role="button" /></span>
            </div>
          }
          <div>
            <label className="text-sm block font-semibold">Nom<span className="text-red-500">*</span></label>
            <input ref={register({
              required: true,
            })}
              style={{ borderColor: errors.name ? "red" : "" }}
              name="name" className="max-h-28 w-full p-2 border border-gray-300 rounded mt-1 text-gray-900" />
            {errors.name && <div className="text-red-500">Votre Nom doit être entre 2 et 50 caractères</div>}
          </div>
          <div>
            <label className="text-sm block font-semibold">Email<span className="text-red-500">*</span></label>
            <input
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalide email',
                }
              })}
              style={{ borderColor: errors.email ? "red" : "" }}
              type="text" name="email" className="w-full p-2 border rounded mt-1 text-gray-900" />
            <div className="text-red-500">{errors.email && errors.email.message}</div>
          </div>
          <div className="flex-grow">
            <label className="text-sm block font-semibold">Message<span className="text-red-500">*</span></label>
            <textarea
              ref={register({
                required: true,
              })}
              style={{ borderColor: errors.message ? "red" : "" }}

              name="message" className="max-h-28 w-full p-2 border border-gray-300 rounded mt-1 text-gray-900" />
          </div>
          <span className="text-sm text-white">champ obligatoire*</span>
          <div>
            <button className={`flex justify-center space-x-2 w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 rounded text-gray-900 ${isLoading && "cursor-not-allowed"}`} disabled={isLoading ? true : false}>
              <span>Envoyer</span>
              {isLoading && <span style={{ borderTopColor: "transparent" }} className="block border-solid animate-spin rounded-full border-gray-900 border-4 h-6 w-6"></span>}
            </button>
          </div>
        </form>

        <div className="xl:self-start pt-8 flex flex-col items-center justify-between content-between">
          <div className="flex justify-around">
            <div className="p-4">
              <p className="text-yellow-500">Notre adresse</p>
              <p className="text-sm md:text-base">21 rue Edouard Vaillant</p>
              <p className="text-sm md:text-base">37000 TOURS</p>
            </div>
            <div className="p-4">
              <p className="text-yellow-500">Nous joindre</p>
              <a className="text-sm md:text-base hover:underline block" href="tel:+33247054290">Tél : 02 47 05 42 90</a>
              <a className="text-sm md:text-base hover:underline block" href="mailto:contact@o3experts.fr">contact@o3experts.fr</a>
            </div>
          </div>
          <a className="xl:pt-8 pb-2" target="blank" href="https://www.google.fr/maps/place/21+Rue+%C3%89douard+Vaillant,+37000+Tours/@47.3880005,0.6944172,17z/data=!3m1!4b1!4m5!3m4!1s0x47fcd436ebe2940d:0xc22e265ea23e242c!8m2!3d47.3878619!4d0.6967109"><PositionIcon className="h-20 w-20 md:w-40 md:h-40 fill-current text-yellow-500" /></a>
        </div>
      </section> */}

    </main>
  );
}
