import React from 'react'



export const NotFound: React.FC = () => {
  return (
    <main className="flex-grow bg-gray-700">
      <h1 className="text-yellow-500 text-center">Not found 404</h1>
    </main>
  );
}