import { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { Home } from './pages/Home';
import { Register } from './pages/Register';
import { Login } from './pages/Login';
import { ForgotPassword } from './pages/ForgotPassword'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Game } from './pages/Game';
import { AuthContext } from './context/context';
import { ProtectedRoute } from './components/ProtectedRoute'
import { getrefreshToken } from './context/actions'
import { syncLogout } from './context/actions'
import { ResetPassword } from './pages/ResetPassword';
import { ChangePassword } from './pages/ChangePassword'
import { NotFound } from './pages/NotFound';
import { Results } from './pages/Results';
import { Summary } from './pages/Summary';
import { Dashboard } from './pages/Dashboard';
import { AddQuestion } from './pages/AddQuestion';
import { Contact } from './pages/Contact';
import { MentionsLegales } from './pages/MentionsLegales';
import { ProtectedAdminRoute } from './components/ProtectedAdminRoute';
import { Forbidden } from './pages/Forbidden';


function App() {
  const { state, dispatch } = useContext(AuthContext);
  const [results, setResults] = useState<Array<Result>>([])
  const history = useHistory()

  const updateResults = (result: Result): void => {
    const newResult = [...results, result]
    setResults(newResult)
  }

  const resetResults = (): void => {
    setResults([])
  }

  useEffect(() => {
    //try silent refresh when user begin a new session
    getrefreshToken(dispatch)
      .then((res) => {

      })
      .catch((e) => {
        //console.log(e)
      })

    //Globle event listener on localstorge to logout user from different tabs/windows
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout') {
        syncLogout(dispatch)
      }
    });
  }, [dispatch, history])


  return (
    <>
      {state.isAuthChecked ?

        <Router>
          <div className="flex flex-col min-h-screen">
            <Header />
            <Switch>
              <Route path='/' exact component={Home} />
              <Route path='/login' exact component={Login} />
              <Route path='/register' exact component={Register} />
              <Route path='/mentions-légales' exact component={MentionsLegales} />
              <Route path='/forgot-password' exact component={ForgotPassword} />
              <Route path='/reset-password/:token' exact component={ResetPassword} />
              <ProtectedRoute path='/change-password' exact component={ChangePassword} />
              <ProtectedRoute updateResults={updateResults} path='/game/:type' exact component={Game} />
              <ProtectedRoute resetResults={resetResults} results={results} path='/summary' exact component={Summary} />
              <ProtectedRoute path='/results' exact component={Results} />
              <ProtectedAdminRoute path='/dashboard' exact component={Dashboard} />
              <ProtectedAdminRoute path='/dashboard/question/:type' exact component={AddQuestion} />
              <ProtectedRoute path='/contact' exact component={Contact} />
              <ProtectedRoute path='/forbidden' exact component={Forbidden} />
              <Route component={NotFound} />
            </Switch>
            <Footer />
          </div>
        </Router>
        :
        <main className="bg-gray-900 flex justify-center items-end w-screen h-screen bg-center bg-no-repeat overflow-hidden" style={{ backgroundImage: "url(/O3-experts-logo.png)" }}>
          <div className="pb-24">
            <span style={{ borderTopColor: "transparent" }} className="mx-auto block border-solid animate-spin  rounded-full border-yellow-500 border-4 h-8 w-8"></span>
          </div>
        </main>
      }
    </>
  );
}

export default App;
