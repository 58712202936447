import React, { useEffect, useState } from 'react'
import { deleteResult, getResults } from '../api';
import { ReactComponent as DeleteIcon } from '../icons/delete.svg'
import { ReactComponent as CloseIcon } from '../icons/close.svg'

export const Results: React.FC = () => {
  const [results, setResults] = useState<Array<Result>>([])
  const [error, setError] = useState<string>('')
  const [deleteMessage, setDeleteMessage] = useState<string>('')
  //used to change the color of the pop-up message
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    getResults().then((res) => {
      if (res.results.length > 0) {
        setResults(res.results)
      } else {
        setError("Pas de résultas à afficher")
      }
    }).catch(error => setError(error))
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [results])

  const handlDelete = (id: string) => {
    deleteResult(id).then((res) => {
      if (res.success) {
        setDeleteMessage(res.success)
        setDeleteSuccess(true)
        //update the results array
        const resultIndex = results.findIndex(result => result.id === id)
        results.splice(resultIndex, 1)
        const newResults = [...results]
        setResults(newResults)
      }
    }).catch(error => {
      setDeleteMessage(error)
      setDeleteSuccess(false)
    })
  }

  return (
    <main className="flex-grow bg-gray-700">
      <h1 className="text-center lg:text-4xl text-yellow-500 p-4">Résultats</h1>
      {error
        ?
        <p className="text-center text-yellow-500">{error}</p>
        :
        <>
          {deleteMessage &&
            <div className={`flex-grow m-5 w-5/6 mx-auto ${deleteSuccess ? "bg-green-400" : "bg-red-400"} text-gray-900 px-4 py-3 mt-3 rounded relative`} role="alert">
              <p className="block sm:inline">{deleteMessage}</p>
              <span className="absolute right-0 "><CloseIcon onClick={() => setDeleteMessage('')} className="fill-current h-6 w-6 text-red-500" role="button" /></span>
            </div>
          }

          {isLoading &&
            <div style={{ borderTopColor: "transparent" }} className=" mx-auto border-solid animate-spin  rounded-full border-yellow-500 border-4 h-8 w-8"></div>
          }

          <div className="overflow-x-auto">
            <div className=" bg-gray-700 flex items-center justify-center font-sans overflow-hidden">
              <div className="w-full px-2 lg:w-5/6">
                <div className="bg-white shadow-md rounded my-6">
                  <table className="min-w-max w-full table-auto overflow-x-auto">
                    <thead>
                      <tr className="bg-gray-900 text-yellow-500 uppercase text-xs leading-normal">
                        <th className="py-2 lg:py-3 px-2 lg:px-6 text-left">N°</th>
                        <th className="py-2 lg:py-3 px-2 lg:px-6 text-left">Prize</th>
                        <th className="py-2 lg:py-3 px-2 lg:px-6 text-center">Questions</th>
                        <th className="py-2 lg:py-3 px-2 lg:px-6 text-center">Date</th>
                        <th className="py-2 lg:py-3 px-2 lg:px-6 text-center">Supprimer</th>
                      </tr>
                    </thead>
                    <tbody className="text-white text-sm font-light">
                      {results.map((result, i) => {
                        const date = new Date(result.createdAt);

                        return (
                          <tr key={result.id} className={`border-b ${(i % 2 === 0) ? "bg-gray-700" : "bg-gray-600"} border-gray-200 hover:bg-gray-800`}>
                            <td className="lg:py-3 lg:px-6 text-left whitespace-nowrap">
                              <div className="flex items-center">
                                <span className="font-medium">{i + 1}</span>
                              </div>
                            </td>
                            <td className="py-2 lg:py-3 lg:px-6 text-left">
                              <div className="flex items-center">
                                <span>{result.prize} 😊</span>
                              </div>
                            </td>
                            <td className="py-2 lg:py-3 lg:px-6 text-center">
                              <div className="flex items-center justify-center">
                                {result.questionNumber}/15
                              </div>
                            </td>
                            <td className="py-2 lg:py-3 lg:px-6 text-center">

                              <span className="bg-yellow-500 text-gray-900 px-1 lg:py-1 lg:px-3 rounded-full text-xs">{date.toLocaleDateString('fr')}</span>
                            </td>
                            <td className="py-2 lg:py-3 lg:px-6 text-center">

                              <div className="flex item-center justify-center">
                                <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">

                                  <DeleteIcon onClick={() => { handlDelete(result.id) }} />
                                </div>
                              </div>
                            </td>
                          </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      }

    </main>
  );
}