import axios from 'axios';
import {RegisterFormDataTypes} from './pages/Register'

const baseUrl = process.env.REACT_APP_API_HOST;

type ResponseType = {
  error: string,
  success: string,
  url: string,
  questions: Array<Question>,
  results: Array<Result>,
  question: Question | null
}

let result: ResponseType = {
  error: '',
  success: '',
  url: '',
  questions: [],
  results: [],
  question: null
}

export const registerUser = async(formData: RegisterFormDataTypes): Promise<ResponseType> =>{
  try {
    const response = await axios.post(`${baseUrl}/auth/register`,{
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      password: formData.password,
      confirmPassword : formData.confirmPassword
    })
    if(response.status === 200 && response.data.success){
      result.success = response.data.success
      result.error = ''
    }
    return result;
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    result.success = ''
    return result;
  }
}

export const forgotPassword = async(email: string): Promise<ResponseType> =>{
  try {
    const response = await axios.post(`${baseUrl}/auth/forgot-password`,{email})
    if(response.status === 200 && response.data.success){
      result.success = response.data.success
      result.url =  response.data.url
      result.error = ''
    }
    return result;
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    result.success = ''
    return result;
  }
}

export const resetPassword = async(password: string, confirmPassword: string, token: string): Promise<ResponseType> =>{
  try {
    const response = await axios.post(`${baseUrl}/auth/reset-password`,{
      password,
      confirmPassword,
      resetToken: token
    })
    if(response.status === 200 && response.data.success){
      result.success = response.data.success
      result.error = ''
    }
    return result;
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    result.success = ''
    return result;
  }
}
export const changePassword = async(id: string = " ", oldPassword: string, newPassword: string): Promise<ResponseType> =>{
  try {
    const response = await axios.post(`${baseUrl}/auth/change-password`,{
      id,
      oldPassword,
      newPassword
    })
    if(response.status === 200 && response.data.success){
      result.success = response.data.success
      result.error = ''
    }
    return result;
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    result.success = ''
    return result;
  }
}
/**
 * Questions endpoints
 */
export const getQuestions = async (type: string = "all"): Promise<ResponseType> =>{
  try {
    const response = await axios.get(`${baseUrl}/questions/${type}`)
    if(response.status === 200){
      result.questions = response.data
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}

export const getRandomQuestions = async (type: string): Promise<ResponseType> =>{
  try {
    const response = await axios.get(`${baseUrl}/random-questions/${type}`)
    if(response.status === 200){
      result.questions = response.data
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}

export const addQuestion = async (questionText: string,explanation: string, difficulty: number, type:string, answers: Array<{text: string,isCorrect: boolean}>): Promise<ResponseType> =>{
  try {
    const response = await axios.post(`${baseUrl}/question`,{
      questionText,
      explanation,
      difficulty,
      type,
      answers
    })
    if(response.status === 200 && response.data.success){
      result.success = response.data.success
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}

export const modifyQuestion = async (id: string,questionText: string,explanation: string, difficulty: number, type:string, answers: Array<{text: string,isCorrect: boolean}>): Promise<ResponseType> =>{
  try {
    const response = await axios.put(`${baseUrl}/question/${id}`,{
      questionText,
      explanation,
      difficulty,
      type,
      answers
    })
    if(response.status === 200 && response.data.success){
      result.success = response.data.success
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}

export const getQuestion = async (id: string): Promise<ResponseType> =>{
  try {
    const response = await axios.get(`${baseUrl}/question/${id}`)

    if(response.status === 200 && response.data.error){
      result.error = "Can't find the question"
      //throw result.error;
    }
    if(response.status === 200 && response.data){
      result.question = response.data
      //result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}

export const deleteQuestion = async (id: string): Promise<ResponseType> =>{
  try {
    const response = await axios.delete(`${baseUrl}/question/${id}`)
    if(response.status === 200 && response.data.success){
      result.success = response.data.success
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}
/**
 * Results endpoints
 */
export const getResults = async (): Promise<ResponseType> =>{
  try {
    const response = await axios.get(`${baseUrl}/results`)
    if(response.status === 200){
      result.results = response.data
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}

export const addResult = async (questionNumber: number, prize: number): Promise<ResponseType> =>{
  try {
    const response = await axios.post(`${baseUrl}/results/add`,{
        questionNumber,
        prize
    })
    if(response.status === 200){
      result.success = "response.success"
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}

export const deleteResult = async (id: string): Promise<ResponseType> =>{
  try {
    const response = await axios.delete(`${baseUrl}/result/${id}`)
    if(response.status === 200 && response.data.success){
      result.success = response.data.success
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}
/**
 * Contact endpoint
 */
export const contact = async (email: string, name: string, message: string): Promise<ResponseType> =>{
  try {
    const response = await axios.post(`${baseUrl}/contact`,{
      name,
      message,
      email
    })
    if(response.status === 200 && response.data.success){
      result.success =  response.data.success
      result.error = ''
    }
    return result
  } catch (error:any) {
    if(error.response.status === 400 && error.response.data.error){
      result.error = error.response.data.error
    }else{
      result.error = 'une erreur est survenue!!'
    }
    throw result.error;
  }
}