import React from 'react'
import {ReactComponent as PhoneIcon} from '../../icons/phone.svg'
import { jokersEnum } from '../../pages/Game';

interface PhoneCallProps {
  handlePhoneCall: ()=>void,
  isOver: boolean,
  canUseJokers: boolean;
  jokers: Map<string,boolean>
}

export const PhoneCall: React.FC<PhoneCallProps> = ({handlePhoneCall, isOver, canUseJokers, jokers}) => {
  const disabled = jokers.get(jokersEnum.PHONE_CALL) || canUseJokers 
  return (
    <button 
      onClick={handlePhoneCall}
      className= {`${jokers.get(jokersEnum.PHONE_CALL) ? "bg-red-900 cursor-not-allowed" : "bg-gray-900"} ${isOver && "-left-60"} transition-all delay-300 duration-1000 left-0 relative uppercase p-4 md:p-6 border border-yellow-500 rounded-full text-yellow-300 shadow-sm hover:shadow-lg bg-gray-900`}
      disabled={disabled}
      aria-label="appeler-un-ami">
      <PhoneIcon className="w-6 h-6 md:w-12 md:h-12"/>
    </button>  
  );
}