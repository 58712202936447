import axios from 'axios'
import {AuthTypes} from './actionsTypes';
import jwt_decode from "jwt-decode";

//TODO move api calls to api.js
const baseUrl = process.env.REACT_APP_API_HOST;
let inMemoryJWT: string = '';
let refreshTimeOutId: number;


type TokenPayload = {
  id: string,
  name: string,
  role: string,
  iat: number,
  exp: number
  email: string
}
//intercepte all the requests and add jwt if existed to the header
axios.interceptors.request.use((config)=>{
  config.headers.authorization = `Bearer ${inMemoryJWT}`
  config.withCredentials = true
  return config;
})


export const login = async(payload:any,dispatch:any,history:any) =>{
  dispatch({type : AuthTypes.LoginRequest})
  try {
    const response = await axios.post(`${baseUrl}/auth/login`,{
      email: payload.email,
      password:payload.password
    })
   
    if(response.status === 200 && response.data.success ){
      const {data} = response
      const tokenPayload: TokenPayload = jwt_decode(response.data.token) 
      const user = {
        id: tokenPayload.id,
        email: tokenPayload.email,
        name: tokenPayload.name,
        role: tokenPayload.role
      }
      dispatch({type : AuthTypes.LoginSuccess, payload: {user, token : response.data.token}})
      setToken(data.token, data.expireIn, dispatch)
      history.push('/')
    }
    //dispatch({type : AuthTypes.LoginError, payload: "une erreur est survenue"})
  } catch (error:any) {
    if(error.response && error.response.status === 400 && error.response.data.error ){
    /*   setTimeout(() => {
        dispatch({type : AuthTypes.LoginError, payload: {error: ''}})
      }, 3000); */
      return dispatch({type : AuthTypes.LoginError, payload: error.response.data})
    }
    dispatch({type : AuthTypes.LoginError, payload: {error: 'Une erreur est survenue'}})
  }
}
const setToken = (token:string, delay: number, dispatch: any)=>{
  inMemoryJWT = token;
  refreshToken(delay,dispatch);
}

const refreshToken = (delay: number ,dispatch:any)=>{
  refreshTimeOutId = window.setTimeout(()=>getrefreshToken(dispatch), delay * 1000 - 5000);
}

export const getrefreshToken = async(dispatch:any)=>{
  try{
    const response = await axios.post(`${baseUrl}/auth/refresh-token`)
    if(response.status === 200 && response.data.success ){
      const {data} = response;
      const tokenPayload: TokenPayload = jwt_decode(response.data.token) 
      const user = {
        id: tokenPayload.id,
        email: tokenPayload.email,
        name: tokenPayload.name,
        role: tokenPayload.role
      }
      dispatch({ type: AuthTypes.TokenRenewed, payload: {user, token : response.data.token} })
      setToken(data.token, data.expireIn, dispatch)
    }
    return response
  }catch(e){
    throw e
  }finally{
    dispatch({type: AuthTypes.CheckAuth})
  }
}

// to support logging out from all windows
export const syncLogout = (dispatch:any)=>{
  axios.post(`${baseUrl}/auth/logout`)
  inMemoryJWT = '';
  abortRefreshToken()
  dispatch({type: AuthTypes.Logout})
}

export const logout = (dispatch:any,history:any)=>{
  const date = Date.now();
  window.localStorage.setItem('logout', date.toString());
  syncLogout(dispatch)
}

const abortRefreshToken = () => {
  if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
  }
};
