import React from 'react'
import {ReactComponent as FiftyFiftyIcon} from '../../icons/fiftyfifty.svg'
import { jokersEnum } from '../../pages/Game';

interface FiftyFiftyProps {
	handleFiftyFifty: ()=>void,
	isOver: boolean,
	canUseJokers: boolean,
	jokers: Map<string,boolean>
}

export const FiftyFifty: React.FC<FiftyFiftyProps> = ({handleFiftyFifty, isOver, canUseJokers, jokers}) => {
	const disabled = jokers.get(jokersEnum.FIFTY_FIFTY) || canUseJokers 
	return (
		<button onClick={handleFiftyFifty}
			className={`${jokers.get(jokersEnum.FIFTY_FIFTY) ? "bg-red-900 cursor-not-allowed " : "bg-gray-900"} ${isOver && "-left-60"} transition-all duration-1000 left-0 relative uppercase p-4 md:p-6 border border-yellow-500 rounded-full text-yellow-500 shadow-sm hover:shadow-lg bg-gray-900`}
			disabled={disabled}
			aria-label="50-50" >
			<FiftyFiftyIcon className="w-6 h-6 md:w-12 md:h-12"/>
		</button>
	);
}