import React from 'react'
import { useTranslation } from 'react-i18next';

type QuestionProps= {
  questionText?: string,
}

export const Question: React.FC<QuestionProps> = ({questionText}) => {
  const {t} = useTranslation();

  return (
    <p className="text-sm md:text-base lg:text-lg border border-yellow-500 rounded text-white px-1 py-2 xl:py-3 text-center bg-gray-900">{t(questionText!)}</p>
  )
}
