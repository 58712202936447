import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router';
import { AuthContext } from '../context/context'

// interface ProtectedRouteProps extends RouteProps {
//   component: React.FC,
//   path: string,
//   results?: string
// }

export const ProtectedRoute: React.ComponentType<any> = ({component: Component, ...rest}) => {
  const {state} = useContext(AuthContext)

  return (
    <Route
      {...rest} 
      render={props =>
        state.token ? 
          (<Component {...props} {...rest} />) :
             (<Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}/>)
      }
    />
  );
}