import React from 'react'
import '../../styles/VoteModal.css'
import { Count } from '../ui/Count';

interface VoteModalProps {
  heights: number[],
  modalDisplay: boolean
}

export const VoteModal: React.FC<VoteModalProps> = ({heights,modalDisplay}) => {

  return (
    <div className = 'votingResults z-30 absolute top-1/4 left-1/3 md:top-1/3 lg:relative lg:top-0 lg:right-0 lg:left-0' style={{visibility: modalDisplay ? "visible" : "hidden"}}>
      <div className = 'votingResult'>
        <Count percentage={heights[0]}/>
        <div className = 'percentageDiagram' style={{height: heights[0]}}></div>
        <div className = 'my-1 text-white'>A</div>
      </div>
      <div className = 'votingResult'>
      <Count percentage={heights[1]}/>
        <div className = 'percentageDiagram' style={{height: heights[1]}}></div>
        <div className = 'my-1 text-white'>B</div>
      </div>
      <div className = 'votingResult'>
        <Count percentage={heights[2]}/>
        <div className = 'percentageDiagram' style={{height: heights[2]}}></div>
        <div className = 'my-1 text-white'>C</div>
      </div>
      <div className = 'votingResult'>
        <Count percentage={heights[3]}/>
        <div className = 'percentageDiagram' style={{height: heights[3]}}></div>
        <div className = 'my-1 text-white'>D</div>
      </div>
    </div>
  );
}