//Variable globale pour changer le son
const audio = new Audio()

const randombetween = (min:number , max:number): number => {
  return Math.floor(Math.random()*(max-min));
}
/**
 * 
 * @param answers
 * @returns index of the correct answer else it returs -1 
 */
export const getIndexOfCorrectAnswer = (answers?: Array<Answer>): number =>{
  if(answers?.length){
    for (const answer of answers) {
      if(answer.isCorrect){
        return answers.indexOf(answer)
      }
    }
  }
  return -1;
}
/**
 * this function returns an array of random numbers based on answers Disability so we could use it in Audiance & Phone jokers
 * * @param answersDisability array of answers Disability(visibilty)- all values are false by default but the fifty joker could change it
 * @returns array of 4 numbers which the sum is 100
 */
export const arrayOfRandomNumbers = (answersDisability: boolean[]): number[] =>{
  const max = 100;
  let randomNumbers: number[] = []
  const reducer = (accumulator:number, currentValue: number) => accumulator + currentValue;
  for(const answer of answersDisability){
    if(answer){
      randomNumbers.push(0)
      continue
    }
    if(randomNumbers.length > 2){
      randomNumbers.push(max - randomNumbers.reduce(reducer))
      break
    }
    if(randomNumbers.length > 0){
      randomNumbers.push(randombetween(1 ,max - randomNumbers.reduce(reducer)))
    }
    if(randomNumbers.length === 0 && !answer) randomNumbers.push(randombetween(1, max))
  }
  return randomNumbers
}

//use Globale variable
export const changeAudio = (path: string): void=>{
  audio.src = path
  if(localStorage.getItem("isMute") && localStorage.getItem("isMute") === "false")
  audio.play()
}

export const getAudio = (): HTMLAudioElement=>{
 return audio;
}

/**
 * takes array of questions and sort them by difficulty
 * @param questions
 * @returns questions
 */
export const sortQuestions= (questions: Array<Question>): Array<Question> =>{
  const sortedQuestions = questions.sort((a,b)=> a.difficulty - b.difficulty)
  return sortedQuestions;
}
/**
 * shuffle an array
 * credit : https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 * @param array of answers
 * @returns sheffled array of answers
 */
export const shuffle = (array: Array<Answer>) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}