import React,{ useEffect, useState, useRef } from 'react'
import { deleteQuestion, getQuestions } from '../api';
import {ReactComponent as DeleteIcon} from '../icons/delete.svg'
import {ReactComponent as CloseIcon} from '../icons/close.svg'
import {ReactComponent as ModifyIcon} from '../icons/modify.svg'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//TODO scroll to the top
export const Dashboard: React.FC = () => {
  const [questions, setQuestions] = useState<Array<Question>>([])
  const [error,setError] = useState<string>('')
  const [selectInput,setSelectInput] = useState<string>('all')
  const [deleteMessage,setDeleteMessage] = useState<string>('')
  //used to change the color of the pop-up message
  const [deleteSuccess,setDeleteSuccess] = useState<boolean>(true)

  const node = useRef<HTMLElement>(null)
  const {t} = useTranslation();

  useEffect(()=>{

    getQuestions(selectInput).then((res)=>{
      if(res.questions.length > 0){
        setQuestions(res.questions)
      }else{
        setError("Pas de Question à afficher")
      }
    }).catch(error => setError(error))
  },[selectInput])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleSelectOption = (e: any)=>{
    setSelectInput(e.target.value)
  }

  const handleDelete = (id: string)=>{
    deleteQuestion(id).then((res)=>{
      if(res.success){
        console.log(res.success)
        setDeleteMessage(res.success)
        setDeleteSuccess(true)
        const questionIndex = questions.findIndex(question => question.id === id)
        questions.splice(questionIndex,1)
        const newQuestions = [...questions]
        setQuestions(newQuestions)

      }

    }).catch(error => {
      setDeleteMessage(error)
      setDeleteSuccess(false)
    })
  }

  return (
    <main ref={node} className="flex-grow bg-gray-700 overflow-auto">
      <h1 className="text-center lg:text-3xl text-yellow-500 p-4">Tableau de bord</h1>
      {deleteMessage && 
        <div className={`max-w-3xl w-full mx-auto ${deleteSuccess ? "bg-green-300" : "bg-red-400"} text-white px-4 py-3 mt-3 rounded relative`} role="alert">
          <p className="block sm:inline">{deleteMessage}</p>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3"><CloseIcon onClick={()=>setDeleteMessage('')} className="fill-current h-6 w-6 text-red-500" role="button"/></span>
        </div>
      }

      <div className="flex justify-center m-4">
        <Link to="/dashboard/question/add" className={`text-xl mr-20 transition text-yellow-500 duration-1000 border rounded p-2 border-yellow-600`}>Ajouter une question</Link>
        <div className="flex items-center">
          <label className="block font-semibold text-white pr-4">filter par type</label>
          <select name="difficulty" className="text-gray-900" onChange={handleSelectOption}>
            <option value="all">Tout</option>
            <option value="autistic">TSA</option>
            <option value="employer">Employer</option>
          </select>
        </div>
      </div>
      <p className="text-sm text-center text-red-400 font-bold">please make sure that there is no duplicate questions with the same type</p>
      {error 
      ?
      <p className="text-center text-yellow-500">{error}</p> 
      :
      <table className="flex-grow m-5 w-5/6 mx-auto bg-gray-200 text-gray-800">
        <thead className="rounded">
          <tr className="text-left border-b-2  bg-gray-900 border-blue-600 rounded text-yellow-500">
            <th className="px-4 py-3">N°</th>
            <th className="px-4 py-3">Question</th>
            <th className="px-4 py-3">Type</th>
            <th className="px-4 py-3">Difficulté</th>
            <th className="px-4 py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question,i) =>{
            return (
            <tr key={i} className=" bg-gray-700 border-b rounded  text-white border-gray-200">
              <td className="px-4 py-3">{i+1}</td>
              <td className="px-4 py-3">{t(question.text)}</td>
              <td className="px-4 py-3">{question.type}</td>
              <td className="px-4 py-3">{(question.difficulty === 1 && "facile") || (question.difficulty === 2 && "moyen") || (question.difficulty === 3 && "difficile")}</td>
              <td className="pl-8 flex space-x-1">
                <Link to={`dashboard/question/${question.id}`}><ModifyIcon className="w-8 h-8"/></Link>
                <DeleteIcon onClick={()=>handleDelete(question.id)} className="w-8 h-8 text-red-600"/>
              </td>
            </tr>)
          })}
         
        </tbody>
      </table>
      }
      
    </main>
  );
}