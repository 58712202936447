import React, { useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { AuthContext } from '../context/context'
import { login } from '../context/actions';
import { AuthTypes } from '../context/actionsTypes';
import { ReactComponent as CloseIcon } from '../icons/close.svg'

interface LoginProps {

}

interface LoginFormData {
  email: string,
  password: string
}

export const Login: React.FC<LoginProps> = () => {
  const { state, dispatch } = useContext(AuthContext);
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<LoginFormData>();

  const onSubmit = handleSubmit((payload) => {
    login(payload, dispatch, history)
  })

  useEffect(() => {
    if (state.token) history.push('/')

    return () => {
      dispatch({ type: AuthTypes.ClearError })
    }
  }, [dispatch, history, state.token])

  return (
    <main className="flex-grow flex flex-col justify-center items-center bg-gray-700">
      <h2 className="font-bold lg:text-4xl md:text-2xl sm:text-2xl text-yellow-500">Se connecter</h2>
      {state.error &&
        <div className="max-w-xs md:max-w-sm w-full mx-auto bg-red-300 border border-red-400 text-gary-700 px-4 py-3 mt-3 rounded relative" role="alert">
          <p className="text-sm text-gray-900">{state.error}</p>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <CloseIcon onClick={() => dispatch({ type: AuthTypes.ClearError })} className="fill-current h-6 w-6 text-red-500" role="close button" />
          </span>
        </div>
      }
      <div className="max-w-md w-full mx-auto mt-4 p-8 text-white">
        <form className="space-y-6" onSubmit={onSubmit}>
          <div>
            <label htmlFor="email" className="text-sm block font-semibold">Email</label>
            <input
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalide email',
                }
              })}
              style={{ borderColor: errors.email ? "red" : "" }}
              type="text" id="email" name="email" className="w-full p-2 border text-gray-900 rounded mt-1" />
            <div className="text-red-500">{errors.email && errors.email.message}</div>
          </div>
          <div>
            <label htmlFor="password" className="text-sm block font-semibold">Mot de passe</label>
            <input
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 24
              })}
              style={{ borderColor: errors.password ? "red" : "" }}
              type="password" id="password" name="password" className="w-full p-2 border text-gray-900 rounded mt-1" />
            <div className="text-red-500">{errors.password && "Votre Mot de pass doit être entre 8 et 24 caractères"}</div>
          </div>
          <div>
            <button className={`flex justify-center space-x-2 w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 rounded text-gray-900 ${state.isLoading && "cursor-not-allowed"}`} disabled={state.isLoading ? true : false}>
              <span> se connecter</span>
              {state.isLoading && <span style={{ borderTopColor: "transparent" }} className="block border-solid animate-spin rounded-full border-gray-900 border-4 h-6 w-6"></span>}
            </button>
          </div>
        </form>
        <Link className="block text-sm underline" to="/forgot-password">J'ai oublié mon mot de passe</Link>
        <Link to="/register">Vous n'avez pas de compte? <span className="underline">S'inscrire</span></Link>
      </div>
    </main>
  );
}
