import React from 'react'
import { Link } from "react-router-dom";

interface FooterProps {

}

export const Footer: React.FC<FooterProps> = () => {

  return (
    <footer className="border-t border-blue-600 bg-gray-900 w-full h-10">
      <ul className="text-xs md:text-base flex justify-around text-yellow-500 h-full items-center">
        <li><Link to="/mentions-légales">Mentions Légales</Link></li>
        <li><Link to="/contact">Nous contacter</Link></li>
      </ul>
    </footer>
  );
}