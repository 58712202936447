import React,{ useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { registerUser } from '../api'
import {ReactComponent as CloseIcon} from '../icons/close.svg'

interface RegisterProps {

}

export interface RegisterFormDataTypes {
  firstname: string,
  lastname: string,
  email: string,
  password: string,
  confirmPassword: string
}

export const Register: React.FC<RegisterProps> = () => {

  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {register, handleSubmit, errors, watch} = useForm<RegisterFormDataTypes>();

  const password = useRef({});
  password.current = watch("password");

  const onSubmit = handleSubmit(async (formData)=>{
    setIsLoading(true)
    const result = await registerUser(formData)
    //const result = setTimeout(()=>{},5000)
    if (result?.error) {
      setError(result.error)
    }
    if (result?.success) {
      setSuccess(result.success)
    }
    setIsLoading(false)
  })

  return (
    <main className="flex-grow flex flex-col justify-center items-center bg-gray-700">
   

        {!success && <h2 className="font-bold lg:text-4xl md:text-2xl sm:text-2xl text-yellow-500">S'inscrire</h2>}
        {error && 
          <div className="max-w-xs md:max-w-sm w-full mx-auto bg-red-300 border border-red-400 text-gary-700 px-4 py-3 mt-3 rounded relative" role="alert">
            <p className="text-sm text-gray-900">{error}</p>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <CloseIcon  onClick={()=> setError('')} className="fill-current h-6 w-6 text-red-500" role="close button"/>
            </span>
          </div>
        }
        {success ? 
        <div className="bg-green-400 border-t-4 border-green-200 rounded-b text-teal-darkest max-w-xs md:max-w-3xl w-full mx-auto mt-4 p-8 mx-4" role="alert">  
              <p className="font-bold">{success}</p>
              <p className="underline text-white"><Link to="/login">se connecter</Link></p>
        </div>
        : 
        <div className="max-w-md w-full mx-auto mt-4 p-8 text-white">
          <form className="space-y-6" onSubmit={onSubmit}>
            <div>
              <label htmlFor="lastname" className="text-sm block font-semibold">Nom</label>
              <input ref={register({
                  required: true,
                  minLength: 2,
                  maxLength: 50
                })}
                style={{borderColor: errors.lastname ? "red" : ""}}
                type="text" id="lastname" name="lastname" className="w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
              {errors.lastname && <div className="text-red-500">Votre Nom doit être entre 2 et 50 caractères</div>}
            </div>
            <div>
              <label htmlFor="firstname" className="text-sm block font-semibold">Prénom</label>
              <input ref={register({
                  required: true,
                  minLength: 2,
                  maxLength: 50
                })}
                style={{borderColor: errors.firstname ? "red" : ""}}
                type="text" id="firstname" name="firstname" className="w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
              {errors.firstname && <div className="text-red-500">Votre prénom doit être entre 2 et 50 caractères</div>}
            </div>
            <div>
              <label htmlFor="email" className="text-sm block font-semibold">Email</label>
              <input 
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalide email',
                  }
                })}
                style={{borderColor: errors.email ? "red" : ""}}
                type="text" id="email" name="email" className="w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
              {errors.email && <div className="text-red-500">{errors.email.message}</div>}
            </div>    
            <div>
              <label htmlFor="password" className="text-sm block font-semibold">Mot de passe</label>
              <input 
                ref={register({
                  required: true,
                  minLength: 8,
                  maxLength: 24
                })}
                style={{borderColor: errors.password ? "red" : ""}}
                type="password" id="password" name="password" className="w-full p-2 border text-gray-900 text-gray-900 border-gray-300 rounded mt-1"/>
              {errors.password && <div className="text-red-500">Votre Mot de pass doit être entre 8 et 24 caractères</div>}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="text-sm block font-semibold">Confirmer le mot de passe</label>
              <input
                ref={
                  register({
                    validate: value => value === password.current || "Les mots de passe ne correspondent pas"
                  })
                }  
                type="password" id="confirmPassword" name="confirmPassword" className="w-full p-2 border text-gray-900 text-gray-900 border-gray-300 rounded mt-1"/>
              {errors.confirmPassword && <div className="text-red-500">{errors.confirmPassword.message}</div>}
            </div>
            <div>
              <button className={`flex justify-center space-x-2 w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 rounded text-gray-900 ${isLoading && "cursor-not-allowed"}`} disabled={isLoading ? true : false }>
                <span>s'inscrire</span>
                {isLoading && <span style={{borderTopColor:"transparent"}} className="block border-solid animate-spin rounded-full border-gray-900 border-4 h-6 w-6"></span>}
              </button>
            </div>
          </form>
          <Link className="text-sm text-white" to="/login">J'ai déja un compte, <span className="underline"> se connecter</span></Link>
        </div>
        }
      
    </main>
  );
}
