import React, { ChangeEvent, useContext } from "react"
import { useTranslation } from 'react-i18next';
import { Language } from '../Language';
import { AuthContext } from '../context/context'
import { AuthTypes } from "../context/actionsTypes";

const Lang = () => {
    const { i18n } = useTranslation();
    const { state, dispatch } = useContext(AuthContext);

    let changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
        let language = event.target.value;
 
        switch (language) {
            case Language.CR:
                dispatch({type: AuthTypes.Language, payload: language})
                i18n.changeLanguage(Language.CR)
                break;
            case Language.FR:
                dispatch({type: AuthTypes.Language, payload: language})
                i18n.changeLanguage(Language.FR);
                break;
        }
        localStorage.setItem("language",language)
    }
 
    return (
        <div>
            <div>
                <select value={state.language} name="language" onChange={changeLanguage} className="w-10 h-10 bg-gray-800 hover:bg-gray-600 rounded-full flex justify-center items-center" aria-label="changer la langue">
                    <option value={Language.FR}>FR</option>
                    <option value={Language.CR}>CR</option>
                </select>
            </div>
        </div>
    )
}
 
export default Lang;