import initialState ,{ InitialStateType } from './initialState';
import { AuthTypes } from './actionsTypes'

//credit https://medium.com/hackernoon/finally-the-typescript-redux-hooks-events-blog-you-were-looking-for-c4663d823b01
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
    }
    : {
        type: Key;
        payload: M[Key];
    }
};

type AuthPayload = {
  [AuthTypes.LoginSuccess] : {
    token : string,
    user : User
  },
  [AuthTypes.LoginError] : {
    error : string
  },
  [AuthTypes.LoginRequest]: undefined,
  [AuthTypes.TokenRenewed] : {
    token: string,
    user: User
  },
  [AuthTypes.Logout]: undefined,
  [AuthTypes.ClearError] : undefined,
  [AuthTypes.CheckAuth] : undefined,
  [AuthTypes.IsMute] : undefined,
  [AuthTypes.Language] : string,
  [AuthTypes.SetRedirectPathOnAuth] : {
    redirectPathOnAuth: string
  }
}
export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

export const reducer = (state: InitialStateType, action: AuthActions): InitialStateType =>{

  switch (action.type) {
    case AuthTypes.LoginRequest:
      return {...state, isLoading:true }
    case AuthTypes.LoginSuccess:
      return { ...state, isLoading: false, user: action.payload.user, token: action.payload.token};
    case AuthTypes.LoginError :
      return {...state, error: action.payload.error, isLoading: false}
    case AuthTypes.ClearError: 
      return {...state, error: ''}
    case AuthTypes.TokenRenewed :
      return {...state, token: action.payload.token, user: action.payload.user}  
    case AuthTypes.Logout:
      return {...state,...initialState, isAuthChecked: true}
    case AuthTypes.CheckAuth:
      return {...state, isAuthChecked: true}
    case AuthTypes.IsMute:
      return {...state, isMute: !state.isMute}
    case AuthTypes.Language :
      return {...state, language: action.payload}
    case AuthTypes.SetRedirectPathOnAuth:
      return {...state, redirectPathOnAuth: action.payload.redirectPathOnAuth}  
    default:
      return state;
  }
}
