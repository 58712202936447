const data = {
  "prizesList": [
    {
      "money":100,
      "isShown": true
    },
    {
      "money":200,
      "isShown": false
    },
    {
      "money":300,
      "isShown": false
    },
    {
      "money":500,
      "isShown": false
    },
    {
      "money":1000,
      "isShown": false
    },
    {
      "money":2000,
      "isShown": false
    },
    {
      "money":4000,
      "isShown": false
    },
    {
      "money":8000,
      "isShown": false
    },
    {
      "money":16000,
      "isShown": false
    },
    {
      "money":32000,
      "isShown": false
    },
    {
      "money":64000,
      "isShown": false
    },
    {
      "money":128000,
      "isShown": false
    },
    {
      "money":250000,
      "isShown": false
    },
    {
      "money":500000,
      "isShown": false
    },
    {
      "money":1000000,
      "isShown": false
    }
  ],
 "questions": [
    {
      "id" : "1",
      "text" : "Quel outil pouvez-vous utiliser pour sensibiliser vos équipes à l'autisme",
      "difficulty" : 2,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "1",
          "text" : "une vidéo d'une personne autiste en pleine crise",
          "isCorrect": false
        },
        {
          "id" : "2",
          "text": "Une vidéo de témoignage d'une personne autiste",
          "isCorrect": true
        },  
        {
          "id" : "3",
          "text": "une photo d'une personne autiste",
          "isCorrect": false
        },
        {
          "id" : "4",
          "text": "pas besoin d'outil",
          "isCorrect": false
        }
      ]
    },
  {
      "id" : "2",
      "text" : "Je réalise des travaux à la porte d’entrée de mon entreprise (bruits intenses prévus toute la journée)",
      "difficulty" : 2,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "5",
          "text" :"Je ne préviens pas mes équipes",
          "isCorrect": false
        },
        {
          "id" : "6",
          "text": "Je préviens les équipes du changement",
          "isCorrect": false
        },
        {
          "id" : "7",
          "text": "Je préviens les équipes du changement",
          "isCorrect": false
        },
        {
          "id" : "8",
          "text": "Je préviens les équipes et leur propose du télétravail",
          "isCorrect": true
        }
      ]
    },
    {
      "id" : "3",
      "text" : "1 + 2 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id": "9",
          "text" : "3",
          "isCorrect": true
        },
        {
          "id" : "1",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "1",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "1",
          "text": "6",
          "isCorrect": false
        }
      ]
    },
    {
      "id" : "4",
      "text" : "4 + 2 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "1",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "1",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "1",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "1",
          "text": "6",
          "isCorrect": true
        }
      ]
    },
    {
      "id" : "5",
      "text" : "34 + 16 = ?",
      "difficulty" : 2,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "1",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "1",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "1",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "2",
          "text": "50",
          "isCorrect": true
        }
      ]
    },
    {
      "id" : "6",
      "text" : "1 + 24 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "2",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "2",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "2",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "2",
          "text": "25",
          "isCorrect": true
        }
      ]
    },
    {
      "id" : "7",
      "text" : "15 + 51 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "2",
          "text" : "66",
          "isCorrect": true
        },
        {
          "id" : "2",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "2",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "2",
          "text": "6",
          "isCorrect": false
        }
      ]
    },
    {
      "id" : "8",
      "text" : "1000 + 666 = ?",
      "difficulty" : 2,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "2",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "3",
          "text": "1666",
          "isCorrect": true
        },
        {
          "id" : "3",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "3",
          "text": "6",
          "isCorrect": false
        }
      ]
    },
    {
      "id" : "9",
      "text" : "15 * 4 = ?",
      "difficulty" : 3,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "3",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "3",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "3",
          "text": "60",
          "isCorrect": true
        },
        {
          "id" : "3",
          "text": "6",
          "isCorrect": false
        }
      ]
    },
    {
      "id" : "1",
      "text" : "4 * 2 + 8 = ?",
      "difficulty" : 3,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "3",
          "text" : "16",
          "isCorrect": true
        },
        {
          "id" : "3",
          "text": "40",
          "isCorrect": false
        },
        {
          "id" : "3",
          "text": "34",
          "isCorrect": false
        },
        {
          "id" : "4",
          "text": "18",
          "isCorrect": false
        }
      ]
    },
    {
      "id" : "1",
      "text" : "4 / 2 + 8 = ?",
      "difficulty" : 3,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "4",
          "text" : "10",
          "isCorrect": true
        },
        {
          "id" : "4",
          "text": "0.4",
          "isCorrect": false
        },
        {
          "id" : "4",
          "text": "8.5",
          "isCorrect": false
        },
        {
          "id" : "4",
          "text": "6",
          "isCorrect": false
        }
      ]
    },
    {
      "id" : "1",
      "text" : "4 + 2 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "4",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "4",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "4",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "4",
          "text": "6",
          "isCorrect": true
        }
      ]
    },
    {
      "id" : "1",
      "text" : "4 + 2 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "4",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "5",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "5",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "5",
          "text": "6",
          "isCorrect": true
        }
      ]
    },
    {
      "id" : "1",
      "text" : "4 + 2 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "5",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "5",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "5",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "5",
          "text": "6",
          "isCorrect": true
        }
      ]
    },
    {
      "id" : "1",
      "text" : "4 + 2 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "5",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "5",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "5",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "6",
          "text": "6",
          "isCorrect": true
        }
      ]
    },
    {
      "id" : "1",
      "text" : "4 + 2 = ?",
      "difficulty" : 1,
      "explanation": "because math is hard",
      "type": "employer",
      "answers" :[
        {
          "id" : "6",
          "text" : "3",
          "isCorrect": false
        },
        {
          "id" : "6",
          "text": "4",
          "isCorrect": false
        },
        {
          "id" : "6",
          "text": "5",
          "isCorrect": false
        },
        {
          "id" : "6",
          "text": "6",
          "isCorrect": true
        }
      ]
    }
  ]
  
}

export default data