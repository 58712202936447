import React, { useContext, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { AuthContext } from '../context/context'

import { ReactComponent as CloseIcon } from '../icons/close.svg'
import { changePassword } from '../api';
import { AuthTypes } from '../context/actionsTypes';


type ChangePasswordFormDataTypes = {
  oldPassword: string
  newPassword: string,
  confirmNewPassword: string
}

export const ChangePassword: React.FC = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm<ChangePasswordFormDataTypes>();
  const password = useRef({});
  password.current = watch("newPassword");

  const onSubmit = handleSubmit((payload) => {
    setIsLoading(true)
    changePassword(state.user?.id, payload.oldPassword, payload.newPassword).then((res) => {
      if (res.success) {
        setSuccess(res.success)
        dispatch({ type: AuthTypes.Logout })
        history.push('/login')
      }
    }).catch((error) => setError(error))
    setIsLoading(false)
  })

  return (
    <main className="flex-grow flex flex-col justify-center items-center bg-gray-700">

      <h2 className="font-bold lg:text-4xl md:text-2xl sm:text-2xl text-yellow-500">Mot de passe oublié</h2>

      {error &&
        <div className="max-w-sm w-full mx-auto bg-red-300 text-white px-4 py-3 mt-3 rounded relative" role="alert">
          <p className="text-sm text-gray-900">{error}</p>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <CloseIcon onClick={() => setError('')} className="fill-current h-6 w-6 text-red-500" role="close button" />
          </span>
        </div>
      }

      <div className="max-w-md w-full mx-auto  text-white  mt-4 p-8 ">
        <form className="space-y-6" onSubmit={onSubmit}>

          <div>
            <label htmlFor="oldPassword" className="text-sm block font-semibold">Mot de passe</label>
            <input
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 24
              })}
              style={{ borderColor: errors.oldPassword ? "red" : "" }}
              type="password" id="oldPassword" name="oldPassword" className="w-full p-2 border text-gray-900 border-gray-300 rounded mt-1" />
            {errors.oldPassword && <div className="text-red-500">Votre Mot de pass doit être entre 8 et 24 caractères</div>}
          </div>

          <div>
            <label htmlFor="newPassword" className="text-sm block font-semibold">Nouveau Mot de passe</label>
            <input
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 24
              })}
              style={{ borderColor: errors.newPassword ? "red" : "" }}
              type="password" id="newPassword" name="newPassword" className="w-full p-2 border text-gray-900 border-gray-300 rounded mt-1" />
            {errors.newPassword && <div className="text-red-500">Votre Mot de pass doit être entre 8 et 24 caractères</div>}
          </div>

          <div>
            <label htmlFor="confirmNewPassword" className="text-sm block font-semibold">Confirmer le nouveau mot de passe</label>
            <input
              ref={
                register({
                  validate: value => value === password.current || "Les mots de passe ne correspondent pas"
                })
              }
              type="password" id="confirmNewPassword" name="confirmNewPassword" className="w-full p-2 border text-gray-900 border-gray-300 rounded mt-1" />
            {errors.confirmNewPassword && <div className="text-red-500">{errors.confirmNewPassword.message}</div>}
          </div>

          <div>
            <button className={`flex justify-center space-x-2 w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 rounded text-gray-900 ${isLoading && "cursor-not-allowed"}`} disabled={isLoading ? true : false}>
              <span>Changer mon mot de passe</span>
              {isLoading && <span style={{ borderTopColor: "transparent" }} className="block border-solid animate-spin rounded-full border-gray-900 border-4 h-6 w-6"></span>}
            </button>
          </div>

        </form>
      </div>
    </main>
  );
}
