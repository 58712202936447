import React,{ useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { addQuestion, getQuestion, modifyQuestion } from '../api';
import {ReactComponent as CloseIcon} from '../icons/close.svg'
import { useTranslation } from 'react-i18next';

export interface QuestionFormDataTypes {
  question: string,
  explanation: string,
  type: string,
  difficulty: number,
  answer1: string,
  answer2: string,
  answer3: string,
  answer4: string,
  isCorrect1: boolean,
  isCorrect2: boolean,
  isCorrect3: boolean,
  isCorrect4: boolean,
}
export const AddQuestion: React.FC = () => {
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [question, setQuestion] = useState<Question | null>(null)
  const {register, handleSubmit, errors, reset} = useForm<QuestionFormDataTypes>();
  const {type} = useParams<{type:string}>();
  const {t} = useTranslation();

  useEffect(() => {
    if(type !== "add"){
      getQuestion(type).then((res)=>{
        if(res.question) setQuestion(res.question)
        //populate from with data
        reset({
          question: t(res.question?.text!),
          explanation: res.question?.explanation,
          type: res.question?.type,
          difficulty: res.question?.difficulty,
          answer1: t(res.question?.answers[0].text!),
          isCorrect1: res.question?.answers[0].isCorrect,
          answer2: t(res.question?.answers[1].text!),
          isCorrect2: res.question?.answers[1].isCorrect,
          answer3: t(res.question?.answers[2].text!),
          isCorrect3: res.question?.answers[2].isCorrect,
          answer4: t(res.question?.answers[3].text!),
          isCorrect4: res.question?.answers[3].isCorrect,
        })
      }).catch(error => console.log(error))
    }
  }, [reset, t, type])

  const onSubmit = handleSubmit((payload)=>{
    const answers = [{text:payload.answer1, isCorrect: payload.isCorrect1},{text:payload.answer2, isCorrect: payload.isCorrect2}, {text:payload.answer3, isCorrect: payload.isCorrect3},{text:payload.answer4, isCorrect: payload.isCorrect4}]
    if(type === 'add'){
      addQuestion(payload.question, payload.explanation, payload.difficulty,payload.type, answers).then((res)=>{
        if(res.success) setSuccess(res.success)
      }).catch(error => setError(error))
      .finally(()=>{
        //clear form
        reset({})
      })
    }else{
      modifyQuestion(type,payload.question, payload.explanation, payload.difficulty,payload.type, answers).then((res)=>{
        if(res.success) setSuccess(res.success)
      }).catch(error => setError(error))
    }

  })

  return (
    <main className="bg-gray-700 justify-center text-white overflow-auto">
      {error && 
        <div className={`max-w-3xl w-full mx-auto bg-red-400 text-white px-4 py-3 mt-3 rounded relative`} role="alert">
          <p className="block sm:inline">{error}</p>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3"><CloseIcon onClick={()=>setError('')} className="fill-current h-6 w-6 text-red-500" role="button"/></span>
        </div>
      }
      {success && 
        <div className={`max-w-3xl w-full mx-auto bg-green-400 text-white px-4 py-3 mt-3 rounded relative`} role="alert">
          <p className="block sm:inline">{success}</p>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3"><CloseIcon onClick={()=>setSuccess('')} className="fill-current h-6 w-6 text-red-500" role="button"/></span>
        </div>
      }
      <h2 className="text-center lg:text-3xl text-yellow-500 p-4">{type === "add" ? "Ajouter" : "Modifier"} une question</h2>
      <div>
      <form className="space-y-6 max-w-xl w-full mx-auto p-8" onSubmit={onSubmit}>
        <div>
          <label className="text-sm block font-semibold">Question<span className="text-red-500">*</span></label>
          <textarea ref={register({
              required: true,
          })}
            style={{borderColor: errors.question ? "red" : ""}}
              name="question" className="max-h-28 w-full p-2 border border-gray-300 text-gray-900 rounded mt-1"/>
          {errors.question && <div className="text-red-500">Votre Nom doit être entre 2 et 50 caractères</div>}
        </div>
        <div>
          <label className="text-sm block font-semibold">Explication</label>
          <textarea ref={register}
            style={{borderColor: errors.explanation ? "red" : ""}}
            name="explanation" className="max-h-28 w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
        </div>
        <div>
        <label className="text-sm block font-semibold">Type<span className="text-red-500">*</span></label>
          <select name="type" className="text-gray-900" ref={register}>
            <option value="autistic">TSA</option>
            <option value="employer">Employer</option>
          </select>
        </div>
        <div>
          <label className="text-sm block font-semibold">Difficulté<span className="text-red-500">*</span></label>
          <select name="difficulty" className="text-gray-900" ref={register}>
            <option value="1">Facile</option>
            <option value="2">Moyen</option>
            <option value="3">Difficile</option>
          </select>
        </div>
        <p className="text-semibold text-yellow-500">Réponses</p>
        <div className="flex justify-between">
          <div className="border-t-2 border-gray-400 flex-grow">
            <label className="text-sm block font-semibold">Réponse1<span className="text-red-500">*</span></label>
            <textarea 
              ref={register({
                required: true,
              })}
              style={{borderColor: errors.answer1 ? "red" : ""}}
              name="answer1" className="max-h-28 w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
          </div>
          <div className="flex items-center pl-1">
            <label className="text-sm block font-semibold pr-2">isCorrect</label>
            <input name="isCorrect1" type="checkbox" ref={register}></input>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="border-t-2 border-gray-400 flex-grow">
            <label className="text-sm block font-semibold">Réponse2<span className="text-red-500">*</span></label>
            <textarea 
              ref={register({
                required: true,
              })}
              style={{borderColor: errors.answer2 ? "red" : ""}}
              name="answer2" className="max-h-28 w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
          </div>
          <div className="flex items-center pl-1">
            <label className="text-sm block font-semibold pr-2">isCorrect</label>
            <input name="isCorrect2" type="checkbox" ref={register}></input>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="border-t-2 border-gray-400 flex-grow">
            <label className="text-sm block font-semibold">Réponse3<span className="text-red-500">*</span></label>
            <textarea 
              ref={register({
                required: true,
              })}
              style={{borderColor: errors.answer3? "red" : ""}}
              name="answer3" className="max-h-28 w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
          </div>
          <div className="flex items-center pl-1">
            <label className="text-sm block font-semibold pr-2">isCorrect</label>
            <input name="isCorrect3" type="checkbox" ref={register}></input>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="border-t-2 border-gray-400 flex-grow">
            <label className="text-sm block font-semibold">Réponse4<span className="text-red-500">*</span></label>
            <textarea 
              ref={register({
                required: true,
              })}
              style={{borderColor: errors.answer4 ? "red" : ""}}
              name="answer4" className="max-h-28 w-full p-2 border text-gray-900 border-gray-300 rounded mt-1"/>
          </div>
          <div className="flex items-center pl-1">
            <label className="text-sm block font-semibold pr-2">isCorrect</label>
            <input  name="isCorrect4" type="checkbox" ref={register}></input>
          </div>
        </div>
        
        <p className="text-sm"><span className="text-red-500">*</span> champ obligatoire</p>
        <div>
          <button className="w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 rounded text-gray-900">{type === "add" ? "Ajouter" : "Modifier"}</button>
        </div>
      </form>
      </div>
    </main>
  );
}