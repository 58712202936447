import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { AuthContext } from '../context/context'
import { ReactComponent as CloseIcon } from '../icons/close.svg'
import { forgotPassword } from '../api';

interface ForgotPasswordProps {

}

interface ForgotPasswordFormDataTypes {
  email: string,
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const { state } = useContext(AuthContext);
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<ForgotPasswordFormDataTypes>();

  const onSubmit = handleSubmit(async ({ email }) => {
    setIsLoading(true)
    const { error, success } = await forgotPassword(email)
    if (error) setError(error)
    if (success) {
      setSuccess(success)
    }
    setIsLoading(false)
  })

  useEffect(() => {
    if (state.token) history.push('/')
  }, [history, state.token])

  return (
    <main className="flex-grow flex flex-col justify-center items-center bg-gray-700">
      <h2 className="font-bold lg:text-4xl md:text-2xl sm:text-2xl text-yellow-500">Mot de passe oublié</h2>
      {error && !success &&
        <div className="max-w-sm w-full mx-auto bg-red-300 border border-red-400 text-gary-700 px-4 py-3 mt-3 rounded relative" role="alert">
          <p className="text-sm text-gray-900">{error}</p>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <CloseIcon onClick={() => setError('')} className="fill-current h-6 w-6 text-red-500" role="close button" />
          </span>
        </div>
      }
      {success ?
        <div className="bg-green-200 border-t-4 border-green-300 rounded-b text-teal-darkest max-w-md w-full mx-auto mt-4 p-8" role="alert">
          <p className="font-bold">{success}</p>
        </div>
        :
        <div className="max-w-md w-full mx-auto mt-4 p-8 text-white">
          <form className="space-y-6" onSubmit={onSubmit}>
            <div>
              <label htmlFor="email" className="text-sm block font-semibold">Email</label>
              <input
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalide email format',
                  }
                })}
                style={{ borderColor: errors.email ? "red" : "" }}
                type="text" id="email" name="email" className="w-full p-2 border border-gray-300 text-gray-900 rounded mt-1" />
              {errors.email && <div className="text-red-500">errors.email.message </div>}
            </div>
            <div>
              <button className={`flex justify-center space-x-2 w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 rounded text-gray-900 ${isLoading && "cursor-not-allowed"}`} disabled={isLoading ? true : false}>
                <span>Envoyer un mail de réinitialisation</span>
                {isLoading && <span style={{ borderTopColor: "transparent" }} className="block border-solid animate-spin rounded-full border-gray-900 border-4 h-6 w-6"></span>}
              </button>
            </div>
          </form>
          <Link className="text-sm underline" to="/login">Revenir à la page de connexion</Link>
        </div>
      }
    </main>
  );
}
