export enum AuthTypes{
 LoginRequest = "LOGIN_REQUEST",
 LoginSuccess = "LOGIN_SUCCESS",
 LoginError = "LOGIN_ERROR",
 TokenRenewed = "TOKEN_RENEWED",
 Logout = "LOGOUT",
 ClearError = "CLEAR_ERROR",
 CheckAuth = "CHECK_AUTH",
 IsMute = "IS_MUTE",
 SetRedirectPathOnAuth = "SET_REDIRECT_PATH_ON_AUTH",
 Language = "LANGUAGE"
}