import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router';
import { AuthContext } from '../context/context'

// interface ProtectedRouteProps extends RouteProps {
//   component: React.FC,
//   path: string,
//   results?: string
// }

export const ProtectedAdminRoute: React.ComponentType<any> = ({component: Component, ...rest}) => {
  const {state} = useContext(AuthContext)

  return (
    <Route
      {...rest} 
      render={props =>
        state.token && state.user?.role === 'admin' ? 
          (<Component {...props} {...rest} />) :
             (<Redirect
              to={{
                pathname: "/forbidden",
                state: { from: props.location }
              }}/>)
      }
    />
  );
}