import React from 'react'
import {ReactComponent as ChangeQuestionIcon} from '../../icons/changeQuestion.svg'
import { jokersEnum } from '../../pages/Game';

interface ChangeQuestionProps {
  handleChangeQuestion: ()=> void,
  isOver: boolean,
  canUseJokers: boolean,
  jokers: Map<string, boolean>
}

export const ChangeQuestion: React.FC<ChangeQuestionProps> = ({handleChangeQuestion ,isOver, canUseJokers, jokers}) => {
  const disabled = jokers.get(jokersEnum.CHANGE_QUESTION) || canUseJokers
  return (
    <button 
      onClick={handleChangeQuestion}
      className= {`${jokers.get(jokersEnum.CHANGE_QUESTION) ? "bg-red-900 cursor-not-allowed" : "bg-gray-900"} ${isOver && "-left-60"} transition-all delay-500 duration-1000 left-0 relative uppercase p-4 md:p-6 border border-yellow-500 rounded-full text-yellow-300 max-w-max shadow-sm hover:shadow-lg bg-gray-900`}
      disabled={disabled}
      aria-label="changer-la-question">
      <ChangeQuestionIcon className="w-6 h-6 md:w-12 md:h-12"/>
    </button>  
  );
}