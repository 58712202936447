import React,{ useEffect, useState } from 'react'
import { Link, RouteProps } from 'react-router-dom';

interface SummaryProps extends RouteProps{
  results: Array<Summary>
  resetResults: ()=>void
}

export const Summary: React.FC<SummaryProps> = (props) => {
  const [results, setResults] = useState<Array<Summary>>([])

  useEffect(() => {
    setResults(props.results)
      return(()=>{
        console.log('unmount')
        props.resetResults()
      })
  }, [props])

  const letters = ["A","B","C","D"]
  return (
   <main className="flex-grow flex flex-col bg-gray-700 text-white p-2 text-sm items-center overflow-auto">
    {results.length > 0 
    ?
    <>
    <p className="text-xl text-yellow-500 font-bold text-center">Vous avez répondu à  {results.length}/15</p>
    <Link to="/" className="border rounded border-yellow-500 p-1 lg:px-3 py-2 m-4 text-lg bg-gray-900 text-yellow-500 hover:bg-yellow-500 hover:border-gray-900 hover:text-gray-900">Rejouer</Link>
    <ul className="space-y-4 flex flex-col md:w-11/12 lg:w-5/6 md:text-lg lg:text-xl xl:text-2xl">
      {results.map((result,i) =>{
        return (
        <li key={i}>
         
          <p className="text-yellow-500 font-semibold lg:p-2">{i+1} : {result.question.text}</p>
        
          {result.question.explanation && <p className="lg:p-2"><span className="text-yellow-500">Info</span> : {result.question.explanation}</p>}
          <ul className="space-y-4 p-1 flex flex-col ">{result.question.answers.map((answer,i)=>{
            
            return <li key={i} className={`${answer.isCorrect ? "bg-green-500" : answer.text === result.choice ? "bg-red-600" : ""} shadow-2xl p-1 lg:p-2`} style={{boxShadow:"-0.5rem 0.5rem 0px rgb(0 0 0 / 30%)"}}>
              <span className="text-yellow-500">{letters[i]}</span> : {answer.text}
            </li>
          })}
          </ul>
        </li>
        )
      })}
    </ul>
    </>
    :
    <p className="text-yellow-500 text-xl lg:text-2xl xl:text-4xl">Rien à afficher</p>
    }
   </main>
  );
}