import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from '../context/context'
import { logout } from '../context/actions'
import { ReactComponent as CaretIcon } from '../icons/caret.svg';
import { ReactComponent as SoundIcon } from '../icons/sound.svg';
import { ReactComponent as MuteIcon } from '../icons/mute.svg';
//import { SoundIcon } from './ui/SoundIcon';
import { AuthTypes } from '../context/actionsTypes';
import { getAudio } from '../helpers/helpers';
import Lang from './Lang';

interface HeaderProps {

}

export const Header: React.FC<HeaderProps> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  //const [isMute, setIsMute] = useState<boolean>(false)
  const { state, dispatch } = useContext(AuthContext);
  const history = useHistory();

  const node = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // event listener to close menu on outside click
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleOutsideClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return
    }
    setIsVisible(false)
  }

  const handleLogout = () => {
    logout(dispatch, history);
    history.push('/login')
  }

  const handleDropdownMenu = () => {
    setIsVisible(isVisible => !isVisible)
  }

  const handleSound = () => {

    let isMute = false;
    if (localStorage.getItem("isMute") === "true") {
      isMute = true;
    }
    const audio = getAudio()
    audio.pause()
    localStorage.setItem("isMute", JSON.stringify(!isMute))
    dispatch({ type: AuthTypes.IsMute })
  }

  return (
    <header className="bg-gray-900 h-16 border-b border-blue-600">
      <nav className="flex justify-between h-full items-center text-yellow-500 px-8 md:px-24">
        {/*  <audio id='mainTheme' src="./sounds/main_theme.mp3" loop autoPlay></audio> */}
        <Link to="/">O3 Experts</Link>
        {!state.token ?
          <ul className="flex">
            <li className="mr-4"><Link to="/register">s'inscrire</Link></li>
            <li className="lg:pl-4"><Link to="/login">se connecter</Link></li>
          </ul>
          :
          <div >
            <ul className="flex items-center mr-4 space-x-3 z-50" >
              <li>{state.user?.name}</li>
              <li>
                <button className="w-10 h-10 bg-gray-800 hover:bg-gray-600 rounded-full flex justify-center items-center" aria-label="menu déroulant">
                  <CaretIcon className="w-8 h-8 fill-current text-yellow-500" onClick={handleDropdownMenu} />
                </button>
              </li>
              <li>
                <Lang/>
              </li>
              <li className="" onClick={handleSound}>
                <button className="w-10 h-10 bg-gray-800 hover:bg-gray-600 rounded-full flex justify-center items-center" aria-label="muet">
                  {state.isMute ? <MuteIcon className="w-5 h-5" /> : <SoundIcon className="w-6 h-6" />}
                </button>
              </li>
            </ul>
            <div ref={node} className="absolute right-24 top-10 mt-5 py-2 w-48 bg-gray-900 border border-blue-600 text-yellow-500 rounded-md shadow-xl z-20" style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
              <Link to="/results" className="block px-4 py-2 text-sm capitalize  hover:bg-blue-500 hover:text-white">
                Résultats
              </Link>
              {state.user?.role === "admin" &&
                <Link to="/dashboard" className="block px-4 py-2 text-sm capitalize  hover:bg-blue-500 hover:text-white">
                  Dashboard
                  <span className="pl-2 text-red-500">admin</span>
                </Link>
              }
              <Link to="/change-password" className="block px-4 py-2 text-sm  hover:bg-blue-500 hover:text-white">
                Changer le mot de passe
              </Link>
              <Link to="#" onClick={handleLogout} className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white">
                se déconnecter
              </Link>
            </div>
          </div>
        }
      </nav>
    </header>
  );
}