import React from 'react'
import {ReactComponent as AudienceIcon} from '../../icons/audience.svg'
import { jokersEnum } from '../../pages/Game'
import '../../styles/Audiance.css'

interface AudianceProps {
  handleAudiance: ()=> void,
  isOver: boolean,
  canUseJokers: boolean,
  jokers: Map<string,boolean>
}

export const Audiance: React.FC<AudianceProps> = ({handleAudiance, isOver, canUseJokers, jokers}) => {
  const disabled = jokers.get(jokersEnum.AUDIENCE) || canUseJokers
  return (
    <button
      onClick={handleAudiance}
      className={`${jokers.get(jokersEnum.AUDIENCE) ? "bg-red-900 cursor-not-allowed" : "bg-gray-900"} ${isOver && "-left-60"} transition-all delay-200 duration-1000 left-0 relative uppercase p-4 md:p-6 border border-yellow-500 rounded-full text-yellow-300 shadow-sm hover:shadow-lg bg-gray-900`} 
      disabled={disabled} aria-label="vote-public">
        <AudienceIcon className="w-6 h-6 md:w-12 md:h-12"/>
    </button>    
  );
}