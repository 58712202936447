import React,{ useState, useEffect, useContext } from 'react'
import { AuthContext } from '../context/context';
import { getIndexOfCorrectAnswer, changeAudio } from '../helpers/helpers';
import { useTranslation } from 'react-i18next';

type AnswerProps = {
  answers?: Array<Answer>
  answersDisabilty: boolean[]
  handleAnswer: (answer: Answer)=>void
  disableJokersTemporarily: ()=>void
}

const PRIMARY_COLOR = "#111827";
const CHOOSEN_ANSWER_COLOR = "#F59E0B";
const CORRECT_ANSWER_COLOR = "green"


export const Answer: React.FC<AnswerProps> = ({answers,answersDisabilty,handleAnswer,disableJokersTemporarily})=>{
  const [backgroundColors, setBackgroundColors] = useState<Array<string>>([PRIMARY_COLOR,PRIMARY_COLOR,PRIMARY_COLOR,PRIMARY_COLOR])
  const [disableAllButtons, setDisableAllButtons] = useState<Array<boolean>>([false,false,false,false])
  const [timer, setTimer] = useState<any>()

  const {t} = useTranslation();

  const {state} = useContext(AuthContext)
  
  useEffect(() => {
    setBackgroundColors([PRIMARY_COLOR,PRIMARY_COLOR,PRIMARY_COLOR,PRIMARY_COLOR])
    return ()=> clearTimeout(timer)
  }, [timer,answers])

  let timer2: any;


  const checkAnswer = (answer: Answer, event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{

    if(!state.isMute) changeAudio('../sounds/final_answer.mp3')
    disableJokersTemporarily()
    if(timer2){
      clearTimeout(timer2)
    } 

    setDisableAllButtons([true,true,true,true])

    if(timer){
      clearTimeout(timer)
    }

    //change the color of choosen answer to orange
    event.currentTarget.style.backgroundColor = CHOOSEN_ANSWER_COLOR
    if(answer.isCorrect){
      setTimer(setTimeout(()=>{
        //TODO try to put sounds in private folder
        if(!state.isMute) changeAudio("../sounds/correct_answer.mp3")
        changeBgColor()
        handleAnswer(answer)
      },3000))
    }else{
      setTimer(window.setTimeout(()=>{
        if(!state.isMute) changeAudio("../sounds/wrong_answer.mp3")
        changeBgColor()
        handleAnswer(answer)
      },3000))
    }

    timer2 = setTimeout(()=>{
      setDisableAllButtons([false,false,false,false])
    },5000)
  }

  const changeBgColor = ()=>{
    const indexOfCorrectAnswer: number = getIndexOfCorrectAnswer(answers)

    let newBackgroundColors: Array<string> = [...backgroundColors]
    newBackgroundColors[indexOfCorrectAnswer] = CORRECT_ANSWER_COLOR;
    setBackgroundColors(newBackgroundColors)
    
  }

  const letters = ["A","B","C","D"]
  return(
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-4">
          {answers?.map((answer,i)=>{
            return( 
              <button
              style={{display : `${answersDisabilty[i] ? 'none' : 'block'}`, backgroundColor: `${backgroundColors[i]}`}}
              onClick={(event)=>checkAnswer(answer,event)}
              key={i}
              
              className={`text-xs md:text-base lg:text-lg border border-yellow-500 focus:outline-none rounded p-2 text-white cursor-pointer`} disabled={disableAllButtons[i]}>
                <span className="flex">
                  <span className={`font-semibold flex-start`}>{letters[i]} :</span> 
                  <span className="pl-2 flex-1">{t(answer.text)}</span>
                </span>
            </button>)
          })}
    </div> 
  )
}